<template>
  <div class="custom-table">
    <div class="table-row header">
      <div class="table-cell">Action</div>
      <div class="table-cell">Contracts</div>
      <div class="table-cell">Date</div>
    </div>
    <div class="text-center mt-3" v-if="!items.length > 0">
      No data available
    </div>
    <div v-for="(item, index) in items" :key="index" class="table-row">
      <div class="table-cell">{{ item.action }}</div>
      <div class="table-cell">{{ item.contracts }}</div>
      <div class="table-cell">{{ item.timestamp }}</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style scoped>
.custom-table {
  width: 100%;
  border-collapse: collapse;
}

.table-row {
  display: flex;
}

.table-cell {
  flex: 1;
  padding: 8px;
  border-bottom: 1px solid #ddd;
  font-size: 14px;
}

.header {
  background-color: #f2f2f2;
}
</style>
