var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "send-info"
  }, [_c('b-modal', {
    attrs: {
      "id": "sendInfo",
      "centered": "",
      "size": "lg"
    },
    scopedSlots: _vm._u([{
      key: "modal-header",
      fn: function fn() {
        return [_c('div', {
          staticClass: "sendInfo-header text-center"
        }, [_c('h4', [_vm._v("Send Information")]), _c('b-button', {
          attrs: {
            "variant": "close-btn"
          },
          on: {
            "click": _vm.closeModal
          }
        }, [_c('i', {
          staticClass: "fas fa-times"
        })])], 1)];
      },
      proxy: true
    }])
  }, [_c('b-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('div', {
    staticClass: "sendInfo-form"
  }, [_c('ValidationObserver', {
    ref: "observer",
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var handleSubmit = _ref.handleSubmit;
        return [_c('form', {
          on: {
            "submit": function submit($event) {
              $event.stopPropagation();
              $event.preventDefault();
              return handleSubmit(_vm.sendInfo);
            }
          }
        }, [_c('div', {
          staticClass: "form-group row"
        }, [_c('div', {
          staticClass: "col-xxl-6 col-xl-6 col-lg-6 mb-3"
        }, [_c('label', [_vm._v("To "), _c('span', {
          staticClass: "required-indc"
        }, [_vm._v("*")])]), _c('input', {
          staticClass: "info-field",
          attrs: {
            "type": "text",
            "disabled": ""
          },
          domProps: {
            "value": _vm.agent.agent_email
          }
        })]), _c('input-text', {
          attrs: {
            "rules": {
              required: true
            },
            "labelFor": "subject",
            "labelName": "Subject",
            "inputName": "subject",
            "vmodel": _vm.form.subject,
            "formGroupClass": "col-xxl-6 col-xl-6 col-lg-6 mb-3",
            "placeholder": "Enter Subject Here"
          },
          on: {
            "update:vmodel": function updateVmodel($event) {
              return _vm.$set(_vm.form, "subject", $event);
            }
          }
        })], 1), _c('input-textarea', {
          attrs: {
            "rules": {
              required: true
            },
            "labelFor": "message",
            "labelName": "Message",
            "inputName": "message",
            "vmodel": _vm.form.message
          },
          on: {
            "update:vmodel": function updateVmodel($event) {
              return _vm.$set(_vm.form, "message", $event);
            }
          }
        }), _c('div', {
          staticClass: "msg-block row"
        }, [_c('div', {
          staticClass: "col-xxl-6 col-xl-6 col-lg-6 mb-3"
        }, [_c('label', [_vm._v("To:")]), _c('ul', [_c('li', [_c('strong', [_vm._v("Rep Code:")]), _vm._v(" " + _vm._s(_vm.agent.agent_code))]), _c('li', [_c('strong', [_vm._v("Username:")]), _vm._v(" " + _vm._s(_vm.agent.agent_email))])])]), _c('div', {
          staticClass: "col-xxl-6 col-xl-6 col-lg-6 mb-3"
        }, [_c('label', [_vm._v("From")]), _c('ul', [_c('li', [_c('strong', [_vm._v(_vm._s(_vm.rep.name))])]), _c('li', [_vm._v(_vm._s(_vm.rep.code))]), _c('li', [_vm._v(_vm._s(_vm.rep.email))]), _c('li', [_vm._v(_vm._s(_vm.rep.phone))])])])]), _c('div', {
          staticClass: "form-group mb-3"
        }, [_c('b-button', {
          staticClass: "univ-btn",
          attrs: {
            "type": "submit",
            "disabled": _vm.isSend
          }
        }, [_vm.isSend ? [_vm._v(" Sending ... ")] : [_vm._v(" Send Email ")]], 2)], 1)], 1)];
      }
    }])
  })], 1)])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }