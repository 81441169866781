<template>
  <div class="send-info">
    <b-modal
      id="downlineSendLoginEmail"
      centered
      size="lg"
      no-close-on-backdrop
      hide-footer
    >
      <template #modal-header>
        <div class="sendInfo-header text-center">
          <h4>Send Login Info Email to Rep</h4>
          <b-button variant="close-btn" @click="closeModal">
            <i class="fas fa-times" />
          </b-button>
        </div>
      </template>
      <b-container fluid>
        <div class="sendInfo-form">
          <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
            <form
              @submit.stop.prevent="handleSubmit(send)"
              ref="downlineSendLoginEmailContainer"
            >
              <div class="form-group row">
                <div class="col-xxl-6 col-xl-6 col-lg-6 mb-3">
                  <label>To <span class="required-indc">*</span></label>
                  <input
                    type="text"
                    class="info-field"
                    :value="agent.agent_email"
                    disabled
                  />
                </div>

                <input-text
                  :rules="{ required: true }"
                  labelFor="subject"
                  labelName="Subject"
                  inputName="subject"
                  :vmodel.sync="form.subject"
                  formGroupClass="col-xxl-6 col-xl-6 col-lg-6 mb-3"
                  placeholder="Enter Subject Here"
                />
              </div>

              <div class="col-xxl-12 mb-3">
                <label>Message</label>
                <ckeditor
                  :editor="editor"
                  v-model="form.message"
                  :config="editorConfig"
                />
              </div>

              <div class="msg-block row">
                <div class="col-xxl-6">
                  <label>From</label>
                  <ul>
                    <li>
                      <strong>{{ rep.name }}</strong>
                    </li>
                    <li>{{ rep.code }}</li>
                    <li>{{ rep.email }}</li>
                    <li>{{ rep.phone }}</li>
                  </ul>
                </div>
              </div>

              <div class="form-group mb-3">
                <b-button class="univ-btn" type="submit" :disabled="isSend">
                  <template v-if="isSend"> Sending ... </template>
                  <template v-else> Send Email </template>
                </b-button>
              </div>
            </form>
          </ValidationObserver>
        </div>
      </b-container>
    </b-modal>
  </div>
</template>
<script>
import Api from "@/resource/Api";
import { mapGetters } from "vuex";
import Helper from "@/resource/Helper";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
export default {
  name: "DownlineRepSendLoginEmail",
  data() {
    return {
      form: {
        agent_id: "",
        subject: "",
        message: "",
      },
      isSend: false,
      editor: ClassicEditor,
      editorConfig: Helper.simpleEditorConfig(),
    };
  },
  props: {
    agent: {
      required: true,
    },
  },
  computed: {
    ...mapGetters({ rep: "rep" }),
  },
  methods: {
    reset() {
      this.form = {
        agent_id: "",
        subject: "",
        message: "",
      };
    },
    closeModal() {
      this.$bvModal.hide("downlineSendLoginEmail");
      this.$emit("onClosedModal");
    },
    send() {
      const url = `v2/send-reps-registration-login-information`;
      this.form.agent_id = this.agent.agent_id;
      this.isSend = true;
      let loader = this.$loading.show({
        container: this.$refs.downlineSendLoginEmailContainer,
      });
      Api.post(url, this.form)
        .then((res) => {
          this.$notify(res.data.message);
          this.closeModal();
          this.reset();
        })
        .catch((err) => {
          this.$notify(err.response.data.message, "error");
        })
        .finally(() => {
          loader.hide();
          this.isSend = false;
        });
    },
  },
};
</script>
<style>
.invalid-block {
  display: block;
}
</style>
