<template>
  <div class="form-group row" v-if="count">
    <label class="contract-level">{{ title }}</label>
    <div class="col-sm-12 d-flex flex-wrap">
      <div class="form-check mr-3" v-for="i in +count" :key="'level' + i">
        <input
          type="radio"
          class="form-check-input"
          :id="`${title}` + 'level' + i"
          :name="name"
          :value="i"
          v-model="selectedLevel"
          :checked="i == +contractLevel"
        />
        <label
          :id="`${contractLevel} + i`"
          class="form-check-label"
          :for="`${title}` + 'level' + i"
        >
          Level {{ i }}
        </label>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    name: {
      type: String,
      default: 'level',
    },
    count: {
      type: Number,
    },
    contractLevel: {
      required: true,
    },
  },
  data() {
    return {
      selectedLevel: this.contractLevel,
    }
  },
  watch: {
    contractLevel(newVal) {
      this.selectedLevel = newVal
    },
    selectedLevel(newVal) {
      this.$emit('level-selected', { level: newVal, name: this.name })
    },
  },
}
</script>
<style>
.form-check-label {
  margin-right: 15px;
  margin-left: -2px;  
}
.form-group .contract-level{
font-weight: bold;
color: #000;
}
</style>
