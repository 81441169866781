var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.count ? _c('div', {
    staticClass: "form-group row"
  }, [_c('label', {
    staticClass: "contract-level"
  }, [_vm._v(_vm._s(_vm.title))]), _c('div', {
    staticClass: "col-sm-12 d-flex flex-wrap"
  }, _vm._l(+_vm.count, function (i) {
    var _domProps;

    return _c('div', {
      key: 'level' + i,
      staticClass: "form-check mr-3"
    }, [_c('input', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: _vm.selectedLevel,
        expression: "selectedLevel"
      }],
      staticClass: "form-check-input",
      attrs: {
        "type": "radio",
        "id": "".concat(_vm.title) + 'level' + i,
        "name": _vm.name
      },
      domProps: (_domProps = {
        "value": i,
        "checked": i == +_vm.contractLevel
      }, _domProps["checked"] = _vm._q(_vm.selectedLevel, i), _domProps),
      on: {
        "change": function change($event) {
          _vm.selectedLevel = i;
        }
      }
    }), _c('label', {
      staticClass: "form-check-label",
      attrs: {
        "id": "".concat(_vm.contractLevel, " + i"),
        "for": "".concat(_vm.title) + 'level' + i
      }
    }, [_vm._v(" Level " + _vm._s(i) + " ")])]);
  }), 0)]) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }