var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    ref: "tableContainer",
    staticClass: "table-responsive"
  }, [_c('table', {
    staticClass: "table"
  }, [_vm._m(0), _c('tbody', [!_vm.rows.records ? _c('tr', [_c('td', {
    attrs: {
      "colspan": "6",
      "align": "center"
    }
  }, [_vm._v("No records found.")])]) : _vm._e(), _vm._l(_vm.rows.records, function (row, index) {
    var _row$upline_details, _row$upline_details2;

    return _c('tr', {
      key: index,
      class: "row-status-".concat(row.agent_status.toLowerCase())
    }, [_c('td', {
      staticStyle: {
        "width": "15%"
      }
    }, [_c('span', [_c('strong', [_vm._v("Signup")]), _c('br'), _vm._v(" " + _vm._s(row.agent_signup_date) + " ")]), _c('span', {
      class: "id-status-".concat(row.agent_status.toLowerCase())
    }, [_vm._v(" " + _vm._s(row.agent_status) + " ")]), _c('span', [_vm._v(" Level: "), _c('b', [_vm._v(_vm._s(row.agent_level))])]), row.contracts.alc ? _c('span', [_vm._v(" Ancillary Level: "), _c('b', [_vm._v(_vm._s(row.contracts.alc))])]) : _vm._e(), row.contracts.pec ? _c('span', [_vm._v(" Premier Level: "), _c('b', [_vm._v(_vm._s(row.contracts.pec))])]) : _vm._e(), row.contracts.bex ? _c('span', [_vm._v(" Bex Level: "), _c('b', [_vm._v(_vm._s(row.contracts.bex))])]) : _vm._e(), row.contracts.fegliAncillary ? _c('span', [_vm._v(" Fegli Level: "), _c('b', [_vm._v(_vm._s(row.contracts.fegliAncillary))])]) : _vm._e(), row.contracts.patriotAncillary ? _c('span', [_vm._v(" Patriot Anciliary Level: "), _c('b', [_vm._v(_vm._s(row.contracts.patriotAncillary))])]) : _vm._e(), row.contracts.patriotPremier ? _c('span', [_vm._v(" Patriot Premier Level: "), _c('b', [_vm._v(_vm._s(row.contracts.patriotPremier))])]) : _vm._e()]), _c('td', [_c('h3', [_vm._v(" " + _vm._s(row.agent_fname) + " " + _vm._s(row.agent_lname) + " " + _vm._s("(".concat(row.agent_code, ")")) + " ")]), row.groups ? _c('span', [_c('strong', [_vm._v("Groups:")]), _c('br'), _vm._l(row.groups, function (g, index) {
      return [_vm._v(" " + _vm._s(g) + " "), index != row.groups.length - 1 ? [_vm._v(",")] : _vm._e()];
    })], 2) : _vm._e()]), row.upline_details ? _c('td', [_c('span', [_vm._v(" " + _vm._s(row.upline_details.upline_first_name) + " " + _vm._s(row.upline_details.upline_last_name) + " ")]), _c('span', [_vm._v(_vm._s((_row$upline_details = row.upline_details) === null || _row$upline_details === void 0 ? void 0 : _row$upline_details.upline_agent_code))]), _c('span', [_c('strong', [_vm._v("Level:")]), _vm._v(" " + _vm._s((_row$upline_details2 = row.upline_details) === null || _row$upline_details2 === void 0 ? void 0 : _row$upline_details2.upline_agent_level) + " ")])]) : _c('td', [_vm._v("Upline agent not found")]), _c('td', [_c('span', [_c('strong', [_vm._v("Location:")]), _vm._v(" " + _vm._s(row.agent_city) + "," + _vm._s(row.agent_state) + " ")]), _c('span', [_c('strong', [_vm._v("Phone 1:")]), _vm._v(" " + _vm._s(row.agent_phone1) + " "), row.agent_phone1 ? _c('valid-icon', {
      attrs: {
        "is-valid": row.is_phone1_valid,
        "className": "valid-icon"
      }
    }) : _vm._e()], 1), row.agent_phone2 ? _c('span', [_c('strong', [_vm._v("Phone 2:")]), _vm._v(" " + _vm._s(row.agent_phone2) + " "), row.agent_phone2 ? _c('valid-icon', {
      attrs: {
        "is-valid": row.is_phone2_valid,
        "className": "valid-icon"
      }
    }) : _vm._e()], 1) : _vm._e(), _c('span', [_c('strong', [_vm._v("Email / Username:")]), _c('br'), _vm._v(" " + _vm._s(row.agent_email) + " "), _c('valid-icon', {
      attrs: {
        "is-valid": row.is_email_valid,
        "className": "valid-icon"
      }
    })], 1), row.agent_profile_link ? _c('span', {
      staticClass: "info-link"
    }, [_vm._v(" " + _vm._s(row.agent_profile_link.title ? "".concat(row.agent_profile_link.title, ":") : '') + " " + _vm._s(row.agent_profile_link.link) + " ")]) : _vm._e()]), _c('td', [_c('ul', [_c('li', [_c('strong', [_vm._v("A:")]), _vm._v(" " + _vm._s(row.agent_active_total) + " ")]), _c('li', [_c('strong', [_vm._v("W:")]), _vm._v(" " + _vm._s(row.agent_withdraw_total) + " ")]), _c('li', [_c('strong', [_vm._v("T:")]), _vm._v(" " + _vm._s(row.agent_termed_total) + " ")]), _c('li', [_c('strong', [_vm._v("Totals:")]), _vm._v(" " + _vm._s(_vm.getStatTotal(row.agent_active_total, row.agent_withdraw_total, row.agent_termed_total)) + " ")])]), _vm.getStatTotal(row.agent_active_total, row.agent_withdraw_total, row.agent_termed_total) > 0 ? [_c('router-link', {
      staticClass: "viewClient-btn",
      attrs: {
        "to": {
          name: 'MemberListByRep',
          params: {
            repId: _vm.encodedId(row.agent_id),
            type: 'direct'
          }
        },
        "target": "_blank"
      }
    }, [_vm._v(" View Clients ")])] : _vm._e()], 2), _c('td', [_c('ul', [_c('li', [_c('strong', [_vm._v("A:")]), _vm._v(" " + _vm._s(row.agent_active_downline_policy) + " ")]), _c('li', [_c('strong', [_vm._v("W:")]), _vm._v(" " + _vm._s(row.agent_withdrawn_downline_policy) + " ")]), _c('li', [_c('strong', [_vm._v("T:")]), _vm._v(" " + _vm._s(row.agent_termed_downline_policy) + " ")]), _c('li', [_c('strong', [_vm._v("Totals:")]), _vm._v(" " + _vm._s(_vm.getStatTotal(row.agent_active_downline_policy, row.agent_withdrawn_downline_policy, row.agent_termed_downline_policy)) + " ")])]), _vm.getStatTotal(row.agent_active_downline_policy, row.agent_withdrawn_downline_policy, row.agent_termed_downline_policy) > 0 ? [_c('router-link', {
      staticClass: "viewClient-btn",
      attrs: {
        "to": {
          name: 'MemberListByRep',
          params: {
            repId: _vm.encodedId(row.agent_id),
            type: 'downline'
          }
        },
        "target": "_blank"
      }
    }, [_vm._v(" View Clients ")])] : _vm._e()], 2), row.agent_status.toLowerCase() !== 'deleted' ? _c('td', [_c('div', {
      staticClass: "action-btn"
    }, [_c('custom-route-button', {
      attrs: {
        "title": "View/Edit Details",
        "iconClass": "fas fa-info",
        "routeLink": {
          name: 'DownlineRepDetail',
          params: {
            downlineRepId: _vm.encodedId(row.agent_id)
          }
        }
      }
    }), row.actions.show_disable_agent == 1 ? [row.actions.disable_agent == 1 ? [_c('custom-button', {
      attrs: {
        "title": "Disable Agent",
        "iconClass": "fas fa-minus"
      },
      nativeOn: {
        "click": function click($event) {
          return _vm.enableDisableAgent(row.agent_id, 0);
        }
      }
    })] : _vm._e()] : _vm._e(), row.actions.show_elite_blue == 1 && row.actions.enable_elite_blue == 0 ? [_c('custom-button', {
      attrs: {
        "title": "Enable Elite Blue",
        "iconClass": "fas fa-user-check"
      },
      nativeOn: {
        "click": function click($event) {
          return _vm.enableEliteBlue(row.agent_id);
        }
      }
    })] : _vm._e(), row.actions.send_email_to_rep ? [_c('custom-button', {
      attrs: {
        "title": "Send Email",
        "iconClass": "fas fa-envelope"
      },
      nativeOn: {
        "click": function click($event) {
          return _vm.openSendEmailModal(row);
        }
      }
    })] : _vm._e(), row.actions.send_rep_login_info ? _c('custom-button', {
      attrs: {
        "title": "Send Reg/Login Email",
        "iconClass": "fas fa-lock"
      },
      on: {
        "onClicked": function onClicked($event) {
          return _vm.openModal(row, 'downlineSendLoginEmail');
        }
      }
    }) : _vm._e(), row.actions.send_rep_login_info ? [_c('custom-button', {
      attrs: {
        "title": "Send Information",
        "iconClass": "far fa-paper-plane"
      },
      nativeOn: {
        "click": function click($event) {
          return _vm.openSendInfoModal(row);
        }
      }
    })] : _vm._e(), row.actions.is_brent_hartley ? [row.actions.is_brent_hartley ? [_c('custom-button', {
      attrs: {
        "title": "Send Contracts",
        "iconClass": "far fa-file"
      },
      nativeOn: {
        "click": function click($event) {
          return _vm.openSendContractModal(row);
        }
      }
    })] : _vm._e()] : _vm._e(), row.show_contract ? [_c('custom-button', {
      attrs: {
        "title": "View Contract Pdf",
        "iconClass": "far fa-eye"
      },
      nativeOn: {
        "click": function click($event) {
          return _vm.openViewContractPdfModal(row);
        }
      }
    })] : _vm._e(), _c('custom-button', {
      attrs: {
        "title": "Benefit Store",
        "iconClass": "fas fa-shopping-cart"
      },
      nativeOn: {
        "click": function click($event) {
          return _vm.agentBenefitStoreLink(row.agent_id);
        }
      }
    }), row.downline_details_count ? _c('custom-route-button', {
      attrs: {
        "title": "View Rep Tree",
        "iconClass": "fas fa-sitemap",
        "routeLink": {
          name: 'DownlineTree',
          params: {
            agent_id: _vm.encodedId(row.agent_id)
          }
        }
      }
    }) : _vm._e(), row.actions.show_default_otp ? _c('custom-button', {
      attrs: {
        "title": "View Default OTP",
        "iconClass": "fas fa-list"
      },
      on: {
        "onClicked": function onClicked($event) {
          return _vm.openModal(row, 'defaultOtpModal');
        }
      }
    }) : _vm._e(), _c('custom-route-button', {
      attrs: {
        "title": "View License",
        "iconClass": "fas fa-id-card",
        "routeLink": {
          name: 'Licenses',
          params: {
            downlineRepId: _vm.encodedId(row.agent_id)
          }
        }
      }
    }), _c('button', {
      directives: [{
        name: "b-tooltip",
        rawName: "v-b-tooltip.hover",
        value: _vm.ViewMsg,
        expression: "ViewMsg",
        modifiers: {
          "hover": true
        }
      }],
      staticClass: "custom-btn",
      attrs: {
        "title": "View msg"
      },
      on: {
        "click": function click($event) {
          return _vm.routeToViewMessage(row);
        }
      }
    }, [_c('img', {
      attrs: {
        "src": require("../../../assets/images/view-msg.svg")
      }
    })]), row.actions.is_deletable ? _c('button', {
      directives: [{
        name: "b-tooltip",
        rawName: "v-b-tooltip.hover",
        value: 'Soft Delete Agent',
        expression: "'Soft Delete Agent'",
        modifiers: {
          "hover": true
        }
      }],
      staticClass: "custom-btn",
      attrs: {
        "title": "Delete Agent"
      },
      on: {
        "click": function click($event) {
          return _vm.checkUpLineAgent(row.agent_id);
        }
      }
    }, [_c('img', {
      staticClass: "delete-agent-btn",
      attrs: {
        "src": require("../../../assets/images/downline-delete.svg")
      }
    })]) : _vm._e()], 2)]) : _vm._e(), row.agent_status.toLowerCase() == 'deleted' && row.actions.is_activatable ? _c('td', [_c('button', {
      directives: [{
        name: "b-tooltip",
        rawName: "v-b-tooltip.hover",
        value: 'Restore Agent',
        expression: "'Restore Agent'",
        modifiers: {
          "hover": true
        }
      }],
      staticClass: "custom-btn",
      attrs: {
        "title": "reactive Agent"
      },
      on: {
        "click": function click($event) {
          return _vm.restoreAgent(row.agent_id);
        }
      }
    }, [_c('img', {
      staticClass: "delete-agent-btn",
      attrs: {
        "src": require("../../../assets/images/reactivate.svg")
      }
    })])]) : _vm._e()]);
  })], 2)]), _c('downline-rep-send-email', {
    attrs: {
      "agent": _vm.selectedAgent
    },
    on: {
      "onClosedModal": _vm.onAgentModalClosed
    }
  }), _c('downline-rep-send-info', {
    attrs: {
      "agent": _vm.selectedAgent
    },
    on: {
      "onClosedModal": _vm.onAgentModalClosed
    }
  }), _c('send-representative-contract-modal', {
    attrs: {
      "agent": _vm.selectedAgent
    },
    on: {
      "onClosedModal": _vm.sendContract
    }
  }), _c('view-contract-pdf-modal', {
    attrs: {
      "agent": _vm.selectedAgent
    },
    on: {
      "onClosedModal": _vm.viewContractPdfModal
    }
  }), _c('assign-level-modal', {
    attrs: {
      "agent": _vm.selectedAgent
    },
    on: {
      "onClosedModal": _vm.assignLevel
    }
  }), _c('downline-rep-send-login-email', {
    attrs: {
      "agent": _vm.selectedAgent
    },
    on: {
      "onClosedModal": _vm.onAgentModalClosed
    }
  }), _c('default-otp-modal', {
    attrs: {
      "row": _vm.selectedAgent,
      "rowType": "A"
    },
    on: {
      "update:row": function updateRow($event) {
        _vm.selectedAgent = $event;
      },
      "onCloseModal": function onCloseModal($event) {
        _vm.selectedAgent = null;
      }
    }
  }), _c('downline-list-modal', {
    attrs: {
      "agentDownlineInfo": _vm.agentDownlineInfo
    },
    on: {
      "getUserList": _vm.getUserList
    }
  })], 1);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('thead', [_c('tr', [_c('th', [_vm._v("Status")]), _c('th', [_vm._v("Name(Code) & Group(s)")]), _c('th', [_vm._v("Upline")]), _c('th', [_vm._v("Contact Info")]), _c('th', [_vm._v("Direct Totals")]), _c('th', [_vm._v("Downline Totals")]), _c('th', [_vm._v("Actions")])])]);
}]

export { render, staticRenderFns }