var render = function () {
  var _domProps;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "form-group row"
  }, [_c('label', [_vm._v(_vm._s(_vm.title))]), _c('div', {
    staticClass: "col-sm-12 d-flex flex-wrap"
  }, [_c('div', {
    staticClass: "form-check mr-3"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.selectedLevel,
      expression: "selectedLevel"
    }],
    staticClass: "form-check-input",
    attrs: {
      "type": "radio",
      "id": "level0",
      "name": _vm.name,
      "value": "0"
    },
    domProps: (_domProps = {
      "checked": _vm.i == +_vm.contractLevel
    }, _domProps["checked"] = _vm._q(_vm.selectedLevel, "0"), _domProps),
    on: {
      "change": function change($event) {
        _vm.selectedLevel = "0";
      }
    }
  }), _c('label', {
    staticClass: "form-check-label",
    attrs: {
      "for": "level0"
    }
  }, [_vm._v("None")])]), _vm._l(+_vm.count, function (i) {
    var _domProps2;

    return _c('div', {
      key: 'level' + i,
      staticClass: "form-check mr-3"
    }, [_c('input', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: _vm.selectedLevel,
        expression: "selectedLevel"
      }],
      staticClass: "form-check-input",
      attrs: {
        "type": "radio",
        "id": 'level' + i,
        "name": _vm.name
      },
      domProps: (_domProps2 = {
        "value": i,
        "checked": i == +_vm.contractLevel
      }, _domProps2["checked"] = _vm._q(_vm.selectedLevel, i), _domProps2),
      on: {
        "change": function change($event) {
          _vm.selectedLevel = i;
        }
      }
    }), _c('label', {
      staticClass: "form-check-label",
      attrs: {
        "for": 'level' + i
      }
    }, [_vm._v("Level " + _vm._s(i))])]);
  })], 2)]);
}
var staticRenderFns = []

export { render, staticRenderFns }