<template>
  <div v-if="count" class="col-md-4 form-group">
    <label class="contract-level">{{ title }}</label>
    <ValidationProvider ref="provider" :name="name" :rules="validateInput ? rules : ''"  v-slot="{ errors, valid, validated, dirty }">
      <select
        class="form-control select-input mb-3"
        :name="name"
        v-model="selectedLevel"
        :class="{ 'invalid': errors.length > 0, 'valid': valid && dirty }"
      >
        <option value="0">Select Contract level</option>
        <option v-for="i in +count" :key="'level' + i" :value="i">
          Level {{ i }}
        </option>
      </select>
      <span class="invalid-feedback">{{ errors[0] }}</span>
    </ValidationProvider>
  </div>
</template>

<script>
import { extend, ValidationProvider } from 'vee-validate';
import { min_value } from 'vee-validate/dist/rules';

extend('min_value', {
  ...min_value,
  message: 'Please select contract level.',
});
export default {
  props: {
    title: String,
    name: {
      type: String,
      default: 'level',
    },
    count: {
      type: Number,
    },
    contractLevel: {
      required: false,
      default:0
    },
    rules: {
      type: Object,
      default: () => ({ required: true, min_value: 1 }),
    },
    validateInput:{
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      selectedLevel: this.contractLevel,
    };
  },
  components: {
    ValidationProvider,
  },
  watch: {
    contractLevel(newVal) {
      this.selectedLevel = newVal;
    },
    selectedLevel(newVal) {
      this.$emit('level-selected', { level: newVal, name: this.name });
    },
  },
  methods: {
    validate() {
      return this.$refs.provider.validate();
    }
  }
};
</script>

<style>
.form-check-label {
  margin-right: 15px;
  margin-left: -2px;
}
.form-group .contract-level {
  font-weight: bold;
  color: #000;
}
.select-input {
  appearance: auto;
  -moz-appearance: auto;
  -webkit-appearance: auto;
}
.invalid {
  border-color: #dc3545 !important;
}
.valid{
  border-color: green !important;
}
.invalid-feedback {
  display: block;
  color: #dc3545;
  margin-top: -15px;
  margin-bottom: 10px;
}
</style>