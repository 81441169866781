<template>
  <div class="table-responsive" ref="tableContainer">
    <table class="table">
      <thead>
        <tr>
          <th>Status</th>
          <th>Name(Code) & Group(s)</th>
          <th>Upline</th>
          <th>Contact Info</th>
          <th>Direct Totals</th>
          <th>Downline Totals</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr v-if="!rows.records">
          <td colspan="6" align="center">No records found.</td>
        </tr>
        <tr
          v-for="(row, index) in rows.records"
          :key="index"
          :class="`row-status-${row.agent_status.toLowerCase()}`"
        >
          <td style="width: 15%;">
            <span>
              <strong>Signup</strong>

              <br />
              {{ row.agent_signup_date }}
            </span>
            <span :class="`id-status-${row.agent_status.toLowerCase()}`">
              {{ row.agent_status }}
            </span>
            <span>
              Level:
              <b>{{ row.agent_level }}</b>
            </span>
            <span v-if="row.contracts.alc">
              Ancillary Level:
              <b>{{ row.contracts.alc }}</b>
            </span>
            <span v-if="row.contracts.pec">
              Premier Level:
              <b>{{ row.contracts.pec }}</b>
            </span>
            <span v-if="row.contracts.bex">
              Bex Level:
              <b>{{ row.contracts.bex }}</b>
            </span>
            <span v-if="row.contracts.fegliAncillary">
              Fegli  Level:
              <b>{{ row.contracts.fegliAncillary }}</b>
            </span>
            <span v-if="row.contracts.patriotAncillary">
              Patriot Anciliary Level:
              <b>{{ row.contracts.patriotAncillary }}</b>
            </span>
            <span v-if="row.contracts.patriotPremier">
              Patriot Premier Level:
              <b>{{ row.contracts.patriotPremier }}</b>
            </span>
          </td>
          <td>
            <h3>
              {{ row.agent_fname }} {{ row.agent_lname }}
              {{ `(${row.agent_code})` }}
            </h3>
            <span v-if="row.groups">
              <strong>Groups:</strong>
              <br />
              <template v-for="(g, index) in row.groups">
                {{ g }}
                <template v-if="index != row.groups.length - 1">,</template>
              </template>
            </span>
          </td>
          <td v-if="row.upline_details">
            <span>
              {{ row.upline_details.upline_first_name }}
              {{ row.upline_details.upline_last_name }}
            </span>
           
            <span>{{ row.upline_details?.upline_agent_code }}</span>
            <span>
              <strong>Level:</strong>
              {{ row.upline_details?.upline_agent_level }}
            </span>
          </td>
          <td v-else>Upline agent not found</td>
          <td>
            <span>
              <strong>Location:</strong>
              {{ row.agent_city }},{{ row.agent_state }}
            </span>
            <span>
              <strong>Phone 1:</strong>
              {{ row.agent_phone1 }}
              <valid-icon
                v-if="row.agent_phone1"
                :is-valid="row.is_phone1_valid"
                className="valid-icon"
              />
            </span>
            <span v-if="row.agent_phone2">
              <strong>Phone 2:</strong>
              {{ row.agent_phone2 }}
              <valid-icon
                v-if="row.agent_phone2"
                :is-valid="row.is_phone2_valid"
                className="valid-icon"
              />
            </span>
            <span>
              <strong>Email / Username:</strong>
              <br />
              {{ row.agent_email }}
              <valid-icon
                :is-valid="row.is_email_valid"
                className="valid-icon"
              />
            </span>
            <span class="info-link" v-if="row.agent_profile_link">
              {{
                row.agent_profile_link.title
                  ? `${row.agent_profile_link.title}:`
                  : ''
              }}
              {{ row.agent_profile_link.link }}
            </span>
          </td>
          <td>
            <ul>
              <li>
                <strong>A:</strong>
                {{ row.agent_active_total }}
              </li>
              <li>
                <strong>W:</strong>
                {{ row.agent_withdraw_total }}
              </li>
              <li>
                <strong>T:</strong>
                {{ row.agent_termed_total }}
              </li>
              <li>
                <strong>Totals:</strong>
                {{
                  getStatTotal(
                    row.agent_active_total,
                    row.agent_withdraw_total,
                    row.agent_termed_total,
                  )
                }}
              </li>
            </ul>
            <template
              v-if="
                getStatTotal(
                  row.agent_active_total,
                  row.agent_withdraw_total,
                  row.agent_termed_total,
                ) > 0
              "
            >
              <router-link
                :to="{
                  name: 'MemberListByRep',
                  params: { repId: encodedId(row.agent_id), type:'direct' },
                }"
                target="_blank"
                class="viewClient-btn"
              >
                View Clients
              </router-link>
            </template>
          </td>
          <td>
            <ul>
              <li>
                <strong>A:</strong>
                {{ row.agent_active_downline_policy }}
              </li>
              <li>
                <strong>W:</strong>
                {{ row.agent_withdrawn_downline_policy }}
              </li>
              <li>
                <strong>T:</strong>
                {{ row.agent_termed_downline_policy }}
              </li>
              <li>
                <strong>Totals:</strong>
                {{
                  getStatTotal(
                    row.agent_active_downline_policy,
                    row.agent_withdrawn_downline_policy,
                    row.agent_termed_downline_policy,
                  )
                }}
              </li>
            </ul>
            <template
              v-if="
                getStatTotal(
                  row.agent_active_downline_policy,
                    row.agent_withdrawn_downline_policy,
                    row.agent_termed_downline_policy,
                ) > 0
              "
            >
              <router-link
                :to="{
                  name: 'MemberListByRep',
                  params: { repId: encodedId(row.agent_id), type:'downline' },
                }"
                target="_blank"
                class="viewClient-btn"
              >
                View Clients
              </router-link>
            </template>
          </td>
          <td v-if="row.agent_status.toLowerCase() !== 'deleted'">
            <div class="action-btn">
              <custom-route-button
                title="View/Edit Details"
                iconClass="fas fa-info"
                :routeLink="{
                  name: 'DownlineRepDetail',
                  params: { downlineRepId: encodedId(row.agent_id) },
                }"
              />

              <template v-if="row.actions.show_disable_agent == 1">
                <template v-if="row.actions.disable_agent == 1">
                  <custom-button
                    title="Disable Agent"
                    iconClass="fas fa-minus"
                    @click.native="enableDisableAgent(row.agent_id, 0)"
                  />
                </template>
              </template>

              <template
                v-if="
                  row.actions.show_elite_blue == 1 &&
                  row.actions.enable_elite_blue == 0
                "
              >
                <custom-button
                  title="Enable Elite Blue"
                  iconClass="fas fa-user-check"
                  @click.native="enableEliteBlue(row.agent_id)"
                />
              </template>
              <!--
              <a href="#" v-b-tooltip.hover title="View Rep Analytics">
                <i class="fas fa-eye" />
              </a> -->

              <template v-if="row.actions.send_email_to_rep">
                <custom-button
                  title="Send Email"
                  iconClass="fas fa-envelope"
                  @click.native="openSendEmailModal(row)"
                />
              </template>

              <custom-button
                title="Send Reg/Login Email"
                iconClass="fas fa-lock"
                v-if="row.actions.send_rep_login_info"
                @onClicked="openModal(row, 'downlineSendLoginEmail')"
              />

              <!-- <br /> -->

              <!-- <custom-button
                title="Download Excel Report"
                iconClass="fas fa-file-excel"
              /> -->

              <template v-if="row.actions.send_rep_login_info">
                <custom-button
                  title="Send Information"
                  iconClass="far fa-paper-plane"
                  @click.native="openSendInfoModal(row)"
                />
              </template>
              <template v-if="row.actions.is_brent_hartley">
               <!-- <custom-button
                  :title="
                    row.agent_status.toLowerCase() === 'pending'
                      ? 'Assign Level And Activate'
                      : 'Update Level'
                  "
                  iconClass="fa fa-key"
                  @click.native="openAssignLevelModal(row)"
                />-->
                <template v-if="row.actions.is_brent_hartley">
                  <custom-button
                    title="Send Contracts"
                    iconClass="far fa-file"
                    @click.native="openSendContractModal(row)"
                  />
                </template>
              
              </template>
              <!-- Contract Download -->
                <template v-if="row.show_contract">
                  <custom-button
                    title="View Contract Pdf"
                    iconClass="far fa-eye"
                    @click.native="openViewContractPdfModal(row)"
                  />
                </template>

<!--              <custom-route-button
                title="Assign Plan"
                iconClass="fas fa-plus"
                :routeLink="{
                  name: 'DownlineRepPlans',
                  params: { downlineRepId: encodedId(row.agent_id) },
                }"
              />
              -->

              <!-- <custom-button
                title="Send Rep Contracts"
                iconClass="fas fa-file-signature"
                v-b-modal.sendRepContract
              /> -->

              <custom-button
                title="Benefit Store"
                iconClass="fas fa-shopping-cart"
                @click.native="agentBenefitStoreLink(row.agent_id)"
              />
              <custom-route-button
                v-if="row.downline_details_count"
                title="View Rep Tree"
                iconClass="fas fa-sitemap"
                :routeLink="{
                  name: 'DownlineTree',
                  params: { agent_id: encodedId(row.agent_id) },
                }"
              />
              <custom-button
                v-if="row.actions.show_default_otp"
                title="View Default OTP"
                iconClass="fas fa-list"
                @onClicked="openModal(row, 'defaultOtpModal')"
              />
              <custom-route-button
                title="View License"
                iconClass="fas fa-id-card"
                :routeLink="{
                  name: 'Licenses',
                  params: { downlineRepId: encodedId(row.agent_id) },
                }"
              />
              <button
                title="View msg"
                class="custom-btn"
                v-b-tooltip.hover="ViewMsg"
                @click="routeToViewMessage(row)"
              >
                <img src="../../../assets/images/view-msg.svg" />
              </button>
              <button
                  title="Delete Agent"
                  class="custom-btn"
                  v-b-tooltip.hover="'Soft Delete Agent'"
                  v-if="row.actions.is_deletable"
                  @click="checkUpLineAgent(row.agent_id)"
              >
                <img src="../../../assets/images/downline-delete.svg" class='delete-agent-btn' />
              </button>
            </div>
          </td>
          <td v-if="row.agent_status.toLowerCase() == 'deleted' && row.actions.is_activatable">
            <button
                title="reactive Agent"
                class="custom-btn"
                v-b-tooltip.hover="'Restore Agent'"
                @click="restoreAgent(row.agent_id)"
            >
              <img src="../../../assets/images/reactivate.svg" class='delete-agent-btn' />
            </button>
          </td>
        </tr>
      </tbody>
    </table>
    
    <downline-rep-send-email
      :agent="selectedAgent"
      @onClosedModal="onAgentModalClosed"
    />
    <downline-rep-send-info
      :agent="selectedAgent"
      @onClosedModal="onAgentModalClosed"
    />
    <send-representative-contract-modal
      :agent="selectedAgent"
      @onClosedModal="sendContract"
    />
    <view-contract-pdf-modal 
     :agent="selectedAgent"
      @onClosedModal="viewContractPdfModal" />
    <assign-level-modal :agent="selectedAgent" @onClosedModal="assignLevel" />

    <downline-rep-send-login-email
      :agent="selectedAgent"
      @onClosedModal="onAgentModalClosed"
    />
    <default-otp-modal
      :row.sync="selectedAgent"
      rowType="A"
      @onCloseModal="selectedAgent = null"
    />
    <downline-list-modal
        :agentDownlineInfo="agentDownlineInfo"
        @getUserList="getUserList"
    />
  </div>
</template>
<script>
import Api from '@/resource/Api'
import DownlineRepSendInfo from '../partials/DownlineRepSendInfo'
import DownlineRepSendEmail from '../partials/DownlineRepSendEmail'
import DownlineRepSendLoginEmail from '../partials/DownlineRepSendLoginEmail'
import DefaultOtpModal from '@/components/ui/DefaultOtpModal.vue'
import CustomButton from '../../../components/ui/CustomButton.vue'
import SendRepresentativeContractModal from './SendRepresentativeContractModal.vue'
import ViewContractPdfModal from './ViewContractPdfModal.vue'
import AssignLevelModal from './AssignLevelModal.vue'
import AdminApi from '@/resource/AdminApi'
import DownlineListModal from "./DownlineListModal";
export default {
  name: 'DownlineRepListTable',
  props: {
    rows: {
      required: true,
    }
  },
  components: {
    DownlineRepSendInfo,
    DownlineRepSendEmail,
    DownlineRepSendLoginEmail,
    DefaultOtpModal,
    CustomButton,
    SendRepresentativeContractModal,
    AssignLevelModal,
    DownlineListModal,
    ViewContractPdfModal,
  },
  data() {
    return {
      selectedAgent: null,
      downlinesDialog: false,
      agentDownlineInfo: {}
    }
  },
  methods: {
    openModal(row, modalId) {
      this.selectedAgent = row
      this.$bvModal.show(modalId)
    },
    encodedId(id) {
      return btoa(id)
    },
    openSendInfoModal(row) {
      console.log('Hello world')
      this.selectedAgent = row
      this.$bvModal.show('sendInfo')
    },
    openSendContractModal(row) {
      this.selectedAgent = row
      this.$bvModal.show('sendContract')
    },
    // new send representaive contract
    openNewSendContractModal(row) {
      this.selectedAgent = row
      this.$bvModal.show('sendNewContract')
    },
    openAssignLevelModal(row) {
      this.selectedAgent = row
      this.$bvModal.show('assignLevel')
    },
    openViewContractPdfModal(row){
      this.selectedAgent= row;
      this.$bvModal.show('viewContractPdfModal')
    },

    agentBenefitStoreLink(agentId) {
      const benefitStoreUrl = this.$endpoint.BENEFIT_STORE_URL
      let url = `${benefitStoreUrl}/agent-home?agent_id=${btoa(agentId)}`
      window.open(url, '_blank')
    },
    routeToViewMessage(row) {
      let app = this
      let userInfo = {
        user_id: '',
        user_message_type: '',
      }
      userInfo.user_id = row.agent_id
      userInfo.user_message_type = 'A'
      app.$store.commit('setViewMessageUserInfo', userInfo)
      app.$router.push('/view-msg')
    },
    onAgentModalClosed() {
      this.selectedAgent = ''
    },
    openSendEmailModal(row) {
      this.selectedAgent = row
      this.$bvModal.show('sendEmail')
    },
    enableDisableAgent(agentId, status) {
      const url = `v2/approve-downline-reps/${agentId}?enable=${status}}`
      const [title, agentStatus] =
        status == 1
          ? ['Activate Agent', 'activate']
          : ['Disable Agent', 'disable']
      this.$confirm({
        title: title,
        message: `Do you want to ${agentStatus} this agent?`,
        button: {
          no: 'No',
          yes: 'Yes',
        },
        callback: (confirm) => {
          if (confirm) {
            let loader = this.$loading.show()
            Api.put(url)
              .then((res) => {
                let response = res.data.message
                this.$notify(response)
                this.$emit('onSuccess')
              })
              .catch((err) => {
                this.$notify(err.response.data.message, 'error')
              })
              .finally(() => {
                loader.hide()
              })
          }
        },
      })
    },
    enableEliteBlue(agentId) {
      const url = `v2/enable-disable-rep-elite-blue/${agentId}?enable=1`
      this.$confirm({
        title: 'Enable Elite Blue',
        message: `Do you want to enable elite blue for this agent?`,
        button: {
          no: 'No',
          yes: 'Yes',
        },
        callback: (confirm) => {
          if (confirm) {
            let loader = this.$loading.show()
            Api.put(url)
              .then((res) => {
                let response = res.data.message
                this.$notify(response)
                this.$emit('onSuccess')
              })
              .catch((err) => {
                this.$notify(err.response.data.message, 'Error', 'danger')
              })
              .finally(() => {
                loader.hide()
              })
          }
        },
      })
    },
    restoreAgent (agentId) {
      this.$confirm({
        title: "Confirm",
        message: "Do you want to restore this agent ?",
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          let loader = this.$loading.show()
          const url = `/api/v2/restore-agent/${agentId}`;
          if (confirm) {
            AdminApi.get(url)
                .then((res) => {
                  let message = res.data.message;
                  this.$notify(message, 'success')
                  this.$emit('onSuccess')
                })
                .catch((err) => {
                  this.$notify(err.response.data.message, 'error')
                }).finally(() => {
              loader.hide()
            })
          } else {
            loader.hide()
          }
        }
      });
    },
    checkUpLineAgent(agentId) {
      let url = `/api/v2/active-downlines-list/${agentId}`
      let loader = this.$loading.show()
      AdminApi.get(url)
          .then((res) => {
            let data = res.data.data;
            if(data.active_agents.length == 0 && !data.active_members){
              this.deleteAgent(agentId)
            } else {
              this.agentDownlineInfo = data
              this.agentDownlineInfo['agentId'] = agentId
              loader.hide()
              this.$bvModal.show('showDownlineList')
            }
          }).catch((err) => {
            this.$notify(err.response.message, 'error')
            console.log(err)
          }).finally(() => {
            loader.hide()
          })
    },
    deleteAgent (agentId) {
      this.$confirm({
        title: "Confirm",
        message: "Do you want to delete this agent?",
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          const url = '/api/v2/delete-agent'
          let data = { agent_id: agentId };
          let loader = this.$loading.show()
          if (confirm) {
            AdminApi.delete(url, data)
                .then((res) => {
                  let message = res.data.message;
                  this.$notify(message, 'success')
                  this.$emit('onSuccess')
                })
                .catch((err) => {
                  console.log(err)
                  this.$notify('Failed to delete agent', 'error')
                }).finally(() => {
              loader.hide()
            })
          }  else {
            loader.hide()
          }
        }
      });
    },
    getUserList () {
      this.$emit('onSuccess')
      this.$bvModal.hide('showDownlineList')
    },
    getStatTotal(activeTotal, withdrawTotal, termedTotal) {
      let total = 0
      total =
        parseInt(activeTotal) + parseInt(withdrawTotal) + parseInt(termedTotal)
      return total
    },
  },
  
}
</script>
<style >
.valid-icon {
  width: 4%;
}

.viewClient-btn {
  display: inline-block;
  text-align: center;
  color: #fff;
  background: #02bc77;
  padding: 8px 25px;
  border-radius: 6px;
  margin-top: 8px;
}

.delete-agent-btn {
  width: 25px;
  position: relative;
  top: -4px;
  height: 25px;
}

.modal-header{
  display: block;
  text-align: center;
}

/* .downlinedata-card table tr td:first-child span {
  width: 110px;
} */
</style>
