<template>
  <div>
    <b-modal id="showDownlineList" centered size="lg" hide-footer>
      <template #modal-header>
        <div class="sendInfo-header w-100 mt-3">
          <h4 class="text-center">Active Agent Lists</h4>
          <b-button variant="close-btn" @click="closeModal">
            <i class="fas fa-times" />
          </b-button>
        </div>
      </template>
      <b-container fluid>
        <div>
          <p>There are policies under this agent.Deleting this agent its policies will move to <strong>{{agentDownlineInfo.agent_ga_name}}.</strong></p>
        </div>
        <div>
          <p>The agent have following downlines:</p>
          <div class="active-users-list">
            <ul v-for="(activeDownlineList, index) in agentDownlineInfo.active_agents" :key="index">
              <li class="text-capitalize">{{activeDownlineList}}</li>
            </ul>
          </div>
        </div>
        <div class="mt-4">
          <p>If you want to delete this agent then these agent's upline will be <strong>{{agentDownlineInfo.agent_ga_name}}.</strong></p>
        </div>
        <div class="downline-btn-container">
          <div class="text-center">
            <strong>Are you sure to delete this agent ?</strong>
          </div>
          <div class="row justify-content-center mt-2">
            <div class="col-xxl-2 col-xl-3 mt-2">
              <button type="submit" class="downline-utv-btn" @click="changeToUpline">Yes</button>
            </div>
            <div class="col-xxl-2 col-xl-3 mt-2">
              <button type="submit" class="downline-utv-btn" @click="closeModal">No</button>
            </div>
          </div>
        </div>
      </b-container>
    </b-modal>
  </div>
</template>

<script>
import AdminApi from '@/resource/AdminApi'
export default {
  name: "DownlineListModal",
  props: {
    agentDownlineInfo: {
      type: Object
    }
  },
  data () {
    return {
      showDownline: ''
    }
  },
  methods: {
    closeModal () {
      this.$bvModal.hide('showDownlineList')
    },
    changeToUpline () {
      const url = `/api/v2/change-agent-upline/${this.agentDownlineInfo.agent_ga}`
      let data = { agent_ids: this.agentDownlineInfo.agent_ids };
      let loader = this.$loading.show()
      AdminApi.put(url, data)
        .then((res) => {
           console.log(res)
           this.deleteAgent();
        })
        .catch((err) => {
          console.log(err)
        }).finally(() => {
        loader.hide()
      })
    },
    deleteAgent () {
      const url = '/api/v2/delete-agent'
      let data = { agent_id: this.agentDownlineInfo.agentId };
      let loader = this.$loading.show()
        AdminApi.delete(url, data)
        .then((res) => {
          let message = res.data.message;
          this.$notify(message, 'success')
          this.$emit('getUserList')
        })
        .catch((err) => {
          console.log(err)
          this.$notify('Failed to delete agent', 'error')
        }).finally(() => {
          loader.hide()
        })
    }
  }
}
</script>

<style scoped>
.active-users-list {
  max-height: 200px; /* Adjust the height as needed */
  overflow-y: auto;
  width: 100%;
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 4px;
}
.downline-utv-btn {
  background: linear-gradient(#0cf, #1597b8 49%, #0567bc);
  border-radius: 6px;
  color: #fff;
  outline: none;
  border: 0;
  width: 100%;
  text-align: center;
  padding: 10px 0;
  margin-top: 10px;
  transition: all ease 0.6s;
  max-width: 300px;
  box-shadow: inherit;
}
.downline-btn-container {
  border: 1px solid #ccc;
  padding: 20px 10px;
  border-radius: 6px;
}
</style>