var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "custom-table"
  }, [_vm._m(0), !_vm.items.length > 0 ? _c('div', {
    staticClass: "text-center mt-3"
  }, [_vm._v(" No data available ")]) : _vm._e(), _vm._l(_vm.items, function (item, index) {
    return _c('div', {
      key: index,
      staticClass: "table-row"
    }, [_c('div', {
      staticClass: "table-cell"
    }, [_vm._v(_vm._s(item.action))]), _c('div', {
      staticClass: "table-cell"
    }, [_vm._v(_vm._s(item.contracts))]), _c('div', {
      staticClass: "table-cell"
    }, [_vm._v(_vm._s(item.timestamp))])]);
  })], 2);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "table-row header"
  }, [_c('div', {
    staticClass: "table-cell"
  }, [_vm._v("Action")]), _c('div', {
    staticClass: "table-cell"
  }, [_vm._v("Contracts")]), _c('div', {
    staticClass: "table-cell"
  }, [_vm._v("Date")])]);
}]

export { render, staticRenderFns }