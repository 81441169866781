import Vue from 'vue';
import axios from 'axios';
import { adminApiUrl } from './EndPoints/endpoints'
export default class Api {
    static get(path) {
        return new Promise((resolve, reject) => {
            let url = adminApiUrl+path
            return axios
                .get(url)
                .then((response) => {
                    Api.resolve(response, resolve, reject);
                })
                .catch((error) => {
                    Api.reject(error, reject);
                });
        });
    }
    static post(path, data) {
        return new Promise((resolve, reject) => {
            let url = adminApiUrl+path
            return axios
                .post(url, data)
                .then((response) => {
                    Api.resolve(response, resolve, reject);
                })
                .catch((error) => {
                    Api.reject(error, reject);
                });
        });
    }
    static put(path, data = null) {
        return new Promise((resolve, reject) => {
            let url = adminApiUrl+path
            return axios
                .put(url, data)
                .then((response) => {
                    Api.resolve(response, resolve, reject);
                })
                .catch((error) => {
                    Api.reject(error, reject);
                });
        });
    }
    static patch(path, data = null) {
        return new Promise((resolve, reject) => {
            let url = adminApiUrl+path
            return axios
                .patch(url, data)
                .then((response) => {
                    Api.resolve(response, resolve, reject);
                })
                .catch((error) => {
                    Api.reject(error, reject);
                });
        });
    }

    static delete(path, data = null) {
        return new Promise((resolve, reject) => {
            let url = adminApiUrl+path
            return axios
                .delete(url, { data })
                .then((response) => {
                    Api.resolve(response, resolve, reject);
                })
                .catch((error) => {
                    Api.reject(error, reject);
                });
        });
    }

    static resolve(response, resolve, reject) {
        if (response.data.status == 'success' || response.status ==200) {
            resolve(response);
        } else if (response.data.status === 'error') {
            // Api.alert('error', response.data.message);
            reject(response);
        } else {
            // Api.alert('error', 'Failed!');
            reject(response);
        }
        //
    }

    static reject(error, reject) {
        //Api.alert('error', error.message);
        reject(error);
    }

    static alert(status, message) {
        switch (status) {
            case 'success':
                Vue.notify({
                    group: 'alert',
                    title: 'Success!',
                    type: 'success',
                    text: message
                });
                break;
            case 'error':
                Vue.notify({
                    group: 'alert',
                    title: 'Error!',
                    type: 'error',
                    text: message,
                });
                break;
            case 'warning':
                Vue.notify({
                    group: 'alert',
                    title: 'Warning!',
                    type: 'warn',
                    text: message
                });
                break;
            case 'info':
                Vue.notify({
                    group: 'alert',
                    title: 'Info!',
                    text: message
                });
                break;
        }
    }

    static notification(status, message) {
        switch (status) {
            case 'success':
                Vue.$toast.open({
                    message: message,
                    type: "success",
                    duration: 3000,
                    dismissible: true,
                    position: "top-right"
                });
                break;
            case 'error':
                Vue.$toast.open({
                    message: message,
                    type: "error",
                    duration: 3000,
                    dismissible: true,
                    position: "top-right"
                });
                break;
            case false:
                Vue.$toast.open({
                    message: message,
                    type: "error",
                    duration: 3000,
                    dismissible: true,
                    position: "top-right"
                });
                break;
            case 'info':
                Vue.$toast.open({
                    message: message,
                    type: "info",
                    duration: 3000,
                    dismissible: true,
                    position: "top-right"
                });
                break;
            case 'warning':
                Vue.$toast.open({
                    message: message,
                    type: "warning",
                    duration: 3000,
                    dismissible: true,
                    position: "top-right"
                });
                break;
        }
    }
}