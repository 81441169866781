var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.count ? _c('div', {
    staticClass: "col-md-4 form-group"
  }, [_c('label', {
    staticClass: "contract-level"
  }, [_vm._v(_vm._s(_vm.title))]), _c('ValidationProvider', {
    ref: "provider",
    attrs: {
      "name": _vm.name,
      "rules": _vm.validateInput ? _vm.rules : ''
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var errors = _ref.errors,
            valid = _ref.valid,
            validated = _ref.validated,
            dirty = _ref.dirty;
        return [_c('select', {
          directives: [{
            name: "model",
            rawName: "v-model",
            value: _vm.selectedLevel,
            expression: "selectedLevel"
          }],
          staticClass: "form-control select-input mb-3",
          class: {
            'invalid': errors.length > 0,
            'valid': valid && dirty
          },
          attrs: {
            "name": _vm.name
          },
          on: {
            "change": function change($event) {
              var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
                return o.selected;
              }).map(function (o) {
                var val = "_value" in o ? o._value : o.value;
                return val;
              });
              _vm.selectedLevel = $event.target.multiple ? $$selectedVal : $$selectedVal[0];
            }
          }
        }, [_c('option', {
          attrs: {
            "value": "0"
          }
        }, [_vm._v("Select Contract level")]), _vm._l(+_vm.count, function (i) {
          return _c('option', {
            key: 'level' + i,
            domProps: {
              "value": i
            }
          }, [_vm._v(" Level " + _vm._s(i) + " ")]);
        })], 2), _c('span', {
          staticClass: "invalid-feedback"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 1934581770)
  })], 1) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }