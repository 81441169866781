  <template>
    <b-modal
      id="viewContractPdfModal"
      modal-class="client-details-modal"
      centered
      size="md"
      hide-footer
      @shown="getData"
    >
    <template #modal-header>
          <div class="resources-header">
            <h4>Signed Contracts</h4>
            <b-button variant="close-btn" @click="closeModal">
              <i class="fas fa-times"></i>
            </b-button>
          </div>
        </template>
        <b-container fluid>
          <div class="resources-modal-content resource-common-modal">
            <vue-scroll :ops="dataScroll">
              <ul>
                <li class="d-flex align-items-center" v-for="(b, index) in contracts" :key="index">
                  <div class="dashres-icon">
                    <img :src="pdfIcon" alt="" />
                  </div>
                  <div style="width: 80%;" class="pdf-links d-flex justify-content-between">
                    <span>{{ b.contract_type.charAt(0).toUpperCase() + b.contract_type.slice(1) }}</span>
                    <template v-if="b.screenshots">
                      <custom-button
                      title="Download Document"
                      iconClass="fas fa-download"
                      @onClicked="downloadHandler(b.screenshots)"
                    />
                    </template>
                    
                  </div>
                </li>
              </ul>
            </vue-scroll>
          </div>
        </b-container>
    </b-modal>
  </template>
  <script>
  import pdfIcon from "@/assets/images/pdf-file.svg"
    import Api from "@/resource/Api";
  export default {
    name: "ViewContractPdfModal",
    props:{
      agent:{
        require:true
      }
    },
    data: () => ({
      dataScroll: {
          vuescroll: {
            mode: "native",
            wheelScrollDuration: "700",
          },
          scrollPanel: {
            maxHeight: 400,
          },
          bar: {
            background: "#ddd",
          },
        },
      contracts: [], 
      pdfIcon,
      apiUrl: process.env.VUE_APP_FILE_DOWNLOAD_URL 
      }
    ),
    methods: {
      closeModal() {
        this.$bvModal.hide("viewContractPdfModal");
      },
      downloadHandler(url) {
      let downLoadLink = `${this.apiUrl}${url}`
      window.open(downLoadLink, '_blank');
    },
      getData() {
        const url = `v2/rep-contract/get-signed-contract?agent_id=${this.agent.agent_id}`;
        let loader = this.$loading.show();
        Api.get(url)
          .then((res) => {
            let data = res.data.data;
            console.log(data)
            this.contracts= data;
            
          })
          .catch((err) => {
            this.$notify(err.response.data.message, "error");
          })
          .finally(() => {
            loader.hide();
          });
      },
    
    },
  
  };
  </script>
  <style>
  modal-content {
      border: 0px !important;
      border-radius: 10px !important;
  }
  .modal-header {
      justify-content: center;
      border-bottom: 0 !important;
      padding: 15px 0;
      position: relative;
  }
  .modal-header button {
      position: absolute;
      top: -15px;
      right: -15px;
      background: #fff;
      color: #000;
      border: 0;
      box-shadow: -2px 2px 13px -4px rgb(0 0 0 / 46%);
      border-radius: 60px;
      font-size: 18px;
      width: 45px;
      height: 45px;
      align-items: center;
      justify-content: center;
      display: flex;
  }
  .resources-modal-content ul {
      list-style: none;
      margin: 0;
      padding: 0;
      padding-bottom: 10px;
  }
  .resources-modal-content ul li {
      list-style: none;
      margin-bottom: 18px;
      border-bottom: 1px solid #efefef;
      padding-bottom: 13px;
  }
  .resources-modal-content ul li span {
      opacity: 0.7;
  }
  .resources-modal-content .pdf-links {
      display: inline-block;
  }
  .resources-modal-content .pdf-links a {
      color: #4db7e9;
  }
  </style>