<template>
  <!--New Send Rep contract Modal -->
  <div class="send-info">
    <b-modal
      id="sendContract"
      @shown="getData"
      centered
      size="lg"
      hide-footer
      hide-header
    >
      <div class="view-rep-header">
        <b-button variant="close-btn" @click="closeModal">
          <i class="fas fa-times"></i>
        </b-button>
      </div>
      <b-container fluid>
        <div class="view-rep-modal-contents mt-3">
          <h5><strong>Send Representative Contract</strong></h5>
          <div class="addMessage-form mt-3">
            <select
              class="select-input mb-3"
              v-model="selectedMainOption"
              @change="toggleChange(selectedMainOption)"
            >
              <option
                v-for="option in filteredOptions"
                :key="option"
                :value="option"
                >{{ formatOption(option) }}</option
              >
            </select>
          </div>
          <!-- Non Category Contract -->

          <div class="row" v-if="selectedMainOption === 'nonCategoryContract'">
            <template
              v-if="
                agent.actions.is_brent_hartley || agent.actions.is_ben_advance
              "
            >
              <SelectInput
                :contractLevel="contractLevels.Abex"
                name="checkedLevelABex"
                :count="
                  contractAgentLevels.Abex - 1 < 0
                    ? 0
                    : contractAgentLevels.Abex - 1
                "
                title="BEX Ancillary Contract"
                @level-selected="handleLevelSelected"
                :validateInput="false"
              />

              <SelectInput
                :contractLevel="contractLevels.Pbex"
                name="checkedLevelPBex"
                title="BEX Premier Contract"
                @level-selected="handleLevelSelected"
                :count="
                  contractAgentLevels.Pbex - 1 < 0
                    ? 0
                    : contractAgentLevels.Pbex - 1
                "
                :validateInput="false"
              />
            </template>
            <template v-if="!agent.actions.is_ben_advance">
              <SelectInput
                :contractLevel="contractLevels.alc"
                title="Smart - Ancilliary Contract"
                name="checkedLevelAlc"
                @level-selected="handleLevelSelected"
                :count="contractAgentLevels.alc"
                :validateInput="false"
              />

              <SelectInput
                :contractLevel="contractLevels.pec"
                name="checkedLevelPec"
                :count="contractAgentLevels.pec"
                title="EWA Contract (Union Only)"
                @level-selected="handleLevelSelected"
                :validateInput="false"
              />

              <template v-if="agent.actions.is_fegli_contract">
                <SelectInput
                  :contractLevel="contractLevels.fegliAncillary"
                  name="checkedLevelFAnci"
                  :count="contractAgentLevels.fegliAncillary"
                  title="GPBP (FEGLI) Contract"
                  @level-selected="handleLevelSelected"
                  :validateInput="false"
                />
              </template>

              <SelectInput
                :contractLevel="
                  checkForPatriotAncillaryLevel(
                    contractLevels.patriotAncillary,
                    contractLevels.patriotPremier
                  )
                "
                name="checkedLevelPAnci"
                :count="
                  checkForPatriotAncillaryLevel(
                    contractAgentLevels.patriotAncillary,
                    contractAgentLevels.patriotPremier
                  )
                "
                title="Patriot - Ancillary Contract"
                @level-selected="handleLevelSelected"
                :validateInput="false"
              />

              <SelectInput
                :contractLevel="contractLevels.patriotPremier"
                name="checkedLevelPPre"
                :count="contractAgentLevels.patriotPremier"
                title="Patriot - Ancillary Contract"
                @level-selected="handleLevelSelected"
                style="visibility: hidden"
                :validateInput="false"
              />
            </template>
          </div>
          <!-- Category Contracts -->
  <ValidationObserver ref="validationObserver">
    <!-- Category Contract -->
    <div class="row" v-if="selectedMainOption === 'categoryContract'">
      <SelectInput
        :contractLevel="contractLevels.med"
        name="checkedLevelMedical"
        :count="contractAgentLevels.med"
        title="Medical"
        @level-selected="handleLevelSelected"
      />
      <SelectInput
        :contractLevel="contractLevels.dent"
        name="checkedLevelDental"
        :count="contractAgentLevels.dent"
        title="Dental"
        @level-selected="handleLevelSelected"
      />
      <SelectInput
        :contractLevel="contractLevels.vision"
        name="checkedLevelVision"
        :count="contractAgentLevels.vision"
        title="Vision"
        @level-selected="handleLevelSelected"
      />
      <SelectInput
        :contractLevel="contractLevels.term_life"
        name="checkedLevelTLife"
        :count="contractAgentLevels.term_life"
        title="Term Life"
        @level-selected="handleLevelSelected"
      />
      <SelectInput
        :contractLevel="contractLevels.bundled"
        name="checkedLevelBundles"
        :count="contractAgentLevels.bundled"
        title="Bundles"
        @level-selected="handleLevelSelected"
      />
      <SelectInput
        :contractLevel="contractLevels.lim_med"
        name="checkedLevelLMedical"
        :count="contractAgentLevels.lim_med"
        title="Limited Medical"
        @level-selected="handleLevelSelected"
      />
      <SelectInput
        :contractLevel="contractLevels.accident"
        name="checkedLevelAccident"
        :count="contractAgentLevels.accident"
        title="Accident"
        @level-selected="handleLevelSelected"
      />
      <SelectInput
        :contractLevel="contractLevels.critical"
        name="checkedLevelCritical"
        :count="contractAgentLevels.critical"
        title="Critical"
        @level-selected="handleLevelSelected"
      />
      <SelectInput
        :contractLevel="contractLevels.hospital"
        name="checkedLevelHospital"
        :count="contractAgentLevels.hospital"
        title="Hospital"
        @level-selected="handleLevelSelected"
      />
      <SelectInput
        :contractLevel="contractLevels.life_style"
        name="checkedLevelLStyle"
        :count="contractAgentLevels.life_style"
        title="Life Style"
        @level-selected="handleLevelSelected"
      />
      <SelectInput
        :contractLevel="contractLevels.pet"
        name="checkedLevelPet"
        :count="contractAgentLevels.pet"
        title="Pet"
        @level-selected="handleLevelSelected"
      />
      <SelectInput
        :contractLevel="contractLevels.guarn_issue"
        name="checkedLevelGIssue"
        :count="contractAgentLevels.guarn_issue"
        title="Guaranteed Issue"
        @level-selected="handleLevelSelected"
      />
      <SelectInput
        :contractLevel="contractLevels.tele_med"
        name="checkedLevelTMedicine"
        :count="contractAgentLevels.tele_med"
        title="Telemedicine"
        @level-selected="handleLevelSelected"
      />
    </div>
    <!-- Premier Contract -->
    <div class="row" v-if="selectedMainOption === 'premierContract'">
      <SelectInput
        :contractLevel="contractLevels.medL7"
        name="checkedLevelMedicalL7"
        :count="contractAgentLevels.medL7"
        title="Medical"
        @level-selected="handleLevelSelected"
      />
      <SelectInput
        :contractLevel="contractLevels.dentL7"
        name="checkedLevelDentalL7"
        :count="contractAgentLevels.dentL7"
        title="Dental"
        @level-selected="handleLevelSelected"
      />
      <SelectInput
        :contractLevel="contractLevels.visionL7"
        name="checkedLevelVisionL7"
        :count="contractAgentLevels.visionL7"
        title="Vision"
        @level-selected="handleLevelSelected"
      />
      <SelectInput
        :contractLevel="contractLevels.term_lifeL7"
        name="checkedLevelTLifeL7"
        :count="contractAgentLevels.term_lifeL7"
        title="Term Life"
        @level-selected="handleLevelSelected"
      />
      <SelectInput
        :contractLevel="contractLevels.bundledL7"
        name="checkedLevelBundlesL7"
        :count="contractAgentLevels.bundledL7"
        title="Bundles"
        @level-selected="handleLevelSelected"
      />
      <SelectInput
        :contractLevel="contractLevels.lim_medL7"
        name="checkedLevelLMedicalL7"
        :count="contractAgentLevels.lim_medL7"
        title="Limited Medical"
        @level-selected="handleLevelSelected"
      />
      <SelectInput
        :contractLevel="contractLevels.accidentL7"
        name="checkedLevelAccidentL7"
        :count="contractAgentLevels.accidentL7"
        title="Accident"
        @level-selected="handleLevelSelected"
      />
      <SelectInput
        :contractLevel="contractLevels.criticalL7"
        name="checkedLevelCriticalL7"
        :count="contractAgentLevels.criticalL7"
        title="Critical"
        @level-selected="handleLevelSelected"
      />
      <SelectInput
        :contractLevel="contractLevels.hospitalL7"
        name="checkedLevelHospitalL7"
        :count="contractAgentLevels.hospitalL7"
        title="Hospital"
        @level-selected="handleLevelSelected"
      />
      <SelectInput
        :contractLevel="contractLevels.life_styleL7"
        name="checkedLevelLStyleL7"
        :count="contractAgentLevels.life_styleL7"
        title="Life Style"
        @level-selected="handleLevelSelected"
      />
      <SelectInput
        :contractLevel="contractLevels.petL7"
        name="checkedLevelPetL7"
        :count="contractAgentLevels.petL7"
        title="Pet"
        @level-selected="handleLevelSelected"
      />
      <SelectInput
        :contractLevel="contractLevels.guarn_issueL7"
        name="checkedLevelGIssueL7"
        :count="contractAgentLevels.guarn_issueL7"
        title="Guaranteed Issue"
        @level-selected="handleLevelSelected"
      />
      <SelectInput
        :contractLevel="contractLevels.tele_medL7"
        name="checkedLevelTMedicineL7"
        :count="contractAgentLevels.tele_medL7"
        title="Telemedicine"
        @level-selected="handleLevelSelected"
      />

      <SelectInput
        :contractLevel="contractLevels.dentSolsticeL7"
        name="checkedLevelSolsticeDental7"
        :count="contractAgentLevels.dentSolsticeL7"
        title="Dental-Solstice"
        @level-selected="handleLevelSelected"
      />

      <SelectInput
        :contractLevel="contractLevels.visionSolsticeL7"
        name="checkedLevelSolsticeVision7"
        :count="contractAgentLevels.visionSolsticeL7"
        title="Vision-Solstice"
        @level-selected="handleLevelSelected"
      />
    </div>
  </ValidationObserver>
          <show-contract-table class="mt-5" :items="actions" />
        </div>
      </b-container>
      <div class="contract-btn-container">
        <b-button
          class="univ-btn contract-btn"
          variant="primary"
          @click="validateAndSendEmail"
          data-dismiss="modal"
        >
          Assign
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import SelectInput from "./SelectInput.vue";
import ShowContractTable from "./ShowContractTable.vue";
import { ValidationObserver } from "vee-validate";
import Api from "@/resource/AdminApi";
import { action_origin } from "../../../utils/constant";
export default {
  name: "NewSendRegistrationLinkEmailModal",
  components: {
    SelectInput,
    ShowContractTable,
    ValidationObserver,
  },
  props: {
    agent: {
      required: true,
    },
  },
  data() {
    return {
      actions: [],
      contractLevels: {},
      contractAgentLevels: {},
      selectedMainOption: "nonCategoryContract",
      optionArrays: [
        "nonCategoryContract",
        "categoryContract",
        "premierContract",
      ],
    };
  },
  computed: {
    filteredOptions() {
      return this.optionArrays.filter((option) =>
        this.shouldShowOption(option)
      );
    },
  },
  methods: {
    checkForPatriotAncillaryLevel(patriotAncillary, patriotPremier = 0) {
      return patriotAncillary > patriotPremier
        ? patriotAncillary
        : patriotPremier;
    },
    closeModal() {
      this.$bvModal.hide("sendContract");
    },
    toggleChange(value) {
      this.isCategory = value;
      this.contractLevels = 0;
      this.contractAgentLevels = 0;
      this.getContractLevels();
      this.getAgentContractLevels();
    },
    hasContractLevel(levels) {
      return levels.some((level) => this.contractAgentLevels[level]);
    },

    formatOption(option) {
      const categoryLevels = [
        "med",
        "dent",
        "vision",
        "term_life",
        "bundled",
        "lim_med",
        "accident",
        "critical",
        "hospital",
        "life_style",
        "pet",
        "guarn_issue",
        "tele_med",
      ];
      const premierLevels = [
        "medL7",
        "dentL7",
        "visionL7",
        "term_lifeL7",
        "bundledL7",
        "lim_medL7",
        "accidentL7",
        "criticalL7",
        "hospitalL7",
        "life_styleL7",
        "petL7",
        "guarn_issueL7",
        "tele_medL7",
        "dentSolsticeL7",
        "visionSolsticeL7",
      ];

      if (
        option === "categoryContract" && this.hasContractLevel(categoryLevels)
      ) {
        return "Category Patriot-Ancillary Contract";
      }
      if (
        option === "premierContract"  &&
        this.hasContractLevel(premierLevels)
      ) {
        return "Category Smart-Ancillary Contract";
      }
      return "Non-Category Contract";
    },

    shouldShowOption(option) {
      const categoryLevels = [
        "med",
        "dent",
        "vision",
        "term_life",
        "bundled",
        "lim_med",
        "accident",
        "critical",
        "hospital",
        "life_style",
        "pet",
        "guarn_issue",
        "tele_med",
      ];
      const premierLevels = [
        "medL7",
        "dentL7",
        "visionL7",
        "term_lifeL7",
        "bundledL7",
        "lim_medL7",
        "accidentL7",
        "criticalL7",
        "hospitalL7",
        "life_styleL7",
        "petL7",
        "guarn_issueL7",
        "tele_medL7",
        "dentSolsticeL7",
        "visionSolsticeL7",
      ];

      if (
        option === "categoryContract"  && 
        this.hasContractLevel(categoryLevels)
      ) {
        return true;
      }
      if (
        option === "premierContract"  && 
        this.hasContractLevel(premierLevels)
      ) {
        return true;
      }
      if (option === "nonCategoryContract") {
        return true;
      }
      return false;
    },

    validateAndSendEmail() {
      this.$refs.validationObserver.validate().then((valid) => {
        if (valid) {
          this.sendContractEmail();
        } else {
          this.$notify("Please fill all required fields correctly.", "error");
        }
      });
    },

    checkAllValues(obj) {
      const values = Object.values(obj);
      console.log(values);
      const allZero = values.every((value) => value == 0);
      console.log(allZero);
      if (allZero) {
        return true;
      }
    },

    sendContractEmail() {
      const data = {};
      let contractType = "";

      const nonCategoryContractData = {};

      if (this.selectedMainOption == "nonCategoryContract") {
        const agentLevelAlc = this.getAgentLevel("checkedLevelAlc", "alc");
        const agentLevelPec = this.getAgentLevel("checkedLevelPec", "pec");
        const agentLevelPecBex = this.getAgentLevel("checkedLevelPBex", "Pbex");
        const agentLevelAlcBex = this.getAgentLevel("checkedLevelABex", "Abex");
        const agentLevelFegliAncillary = this.getAgentLevel(
          "checkedLevelFAnci",
          "fegliAncillary"
        );
        const agentLevelPatriotAncillary = this.getAgentLevel(
          "checkedLevelPAnci",
          "patriotAncillary"
        );

        data["agent_level_alc"] = agentLevelAlc;
        nonCategoryContractData["agent_level_alc"] = agentLevelAlc;

        data["agent_level_pec"] = agentLevelPec;
        nonCategoryContractData["agent_level_pec"] = agentLevelPec;

        data["agent_level_pec_bex"] = agentLevelPecBex;
        nonCategoryContractData["agent_level_pec_bex"] = agentLevelPecBex;

        data["agent_level_alc_bex"] = agentLevelAlcBex;
        nonCategoryContractData["agent_level_alc_bex"] = agentLevelAlcBex;

        data["agent_level_fegli_premier"] = 0;
        nonCategoryContractData["agent_level_fegli_premier"] = 0;

        data["agent_level_fegli_ancillary"] = agentLevelFegliAncillary;
        nonCategoryContractData[
          "agent_level_fegli_ancillary"
        ] = agentLevelFegliAncillary;

        data["agent_level_patriot_premier"] = 0;
        nonCategoryContractData["agent_level_patriot_premier"] = 0;

        data["agent_level_patriot_ancillary"] = agentLevelPatriotAncillary;
        nonCategoryContractData[
          "agent_level_patriot_ancillary"
        ] = agentLevelPatriotAncillary;

        contractType = "N-CatContrs";
      }

      if (this.selectedMainOption == "categoryContract") {
        (data["agent_level_med"] = this.getAgentLevel(
          "checkedLevelMedical",
          "med"
        )),
          (data["agent_level_dent"] = this.getAgentLevel(
            "checkedLevelDental",
            "dent"
          )),
          (data["agent_level_vision"] = this.getAgentLevel(
            "checkedLevelVision",
            "vision"
          )),
          (data["agent_level_term_life"] = this.getAgentLevel(
            "checkedLevelTLife",
            "term_life"
          )),
          (data["agent_level_bundled"] = this.getAgentLevel(
            "checkedLevelBundles",
            "bundled"
          )),
          (data["agent_level_lim_med"] = this.getAgentLevel(
            "checkedLevelLMedical",
            "lim_med"
          )),
          (data["agent_level_accident"] = this.getAgentLevel(
            "checkedLevelAccident",
            "accident"
          )),
          (data["agent_level_critical"] = this.getAgentLevel(
            "checkedLevelCritical",
            "critical"
          )),
          (data["agent_level_hospital"] = this.getAgentLevel(
            "checkedLevelHospital",
            "hospital"
          )),
          (data["agent_level_life_style"] = this.getAgentLevel(
            "checkedLevelLStyle",
            "life_style"
          )),
          (data["agent_level_pet"] = this.getAgentLevel(
            "checkedLevelPet",
            "pet"
          )),
          (data["agent_level_guarn_issue"] = this.getAgentLevel(
            "checkedLevelGIssue",
            "guarn_issue"
          )),
          (data["agent_level_tele_med"] = this.getAgentLevel(
            "checkedLevelTMedicine",
            "tele_med"
          )),
          (contractType = "catContrs");
      }

      if (this.selectedMainOption == "premierContract") {
        (data["agent_level_medL7"] = this.getAgentLevel(
          "checkedLevelMedicalL7",
          "medL7"
        )),
          (data["agent_level_dentL7"] = this.getAgentLevel(
            "checkedLevelDentalL7",
            "dentL7"
          )),
          (data["agent_level_visionL7"] = this.getAgentLevel(
            "checkedLevelVisionL7",
            "visionL7"
          )),
          (data["agent_level_term_lifeL7"] = this.getAgentLevel(
            "checkedLevelTLifeL7",
            "term_lifeL7"
          )),
          (data["agent_level_bundledL7"] = this.getAgentLevel(
            "checkedLevelBundlesL7",
            "bundledL7"
          )),
          (data["agent_level_lim_medL7"] = this.getAgentLevel(
            "checkedLevelLMedicalL7",
            "lim_medL7"
          )),
          (data["agent_level_accidentL7"] = this.getAgentLevel(
            "checkedLevelAccidentL7",
            "accidentL7"
          )),
          (data["agent_level_criticalL7"] = this.getAgentLevel(
            "checkedLevelCriticalL7",
            "criticalL7"
          )),
          (data["agent_level_hospitalL7"] = this.getAgentLevel(
            "checkedLevelHospitalL7",
            "hospitalL7"
          )),
          (data["agent_level_life_styleL7"] = this.getAgentLevel(
            "checkedLevelLStyleL7",
            "life_styleL7"
          )),
          (data["agent_level_petL7"] = this.getAgentLevel(
            "checkedLevelPetL7",
            "petL7"
          )),
          (data["agent_level_guarn_issueL7"] = this.getAgentLevel(
            "checkedLevelGIssueL7",
            "guarn_issueL7"
          )),
          (data["agent_level_tele_medL7"] = this.getAgentLevel(
            "checkedLevelTMedicineL7",
            "tele_medL7"
          )),
          (data["agent_level_dentSolsticeL7"] = this.getAgentLevel(
            "checkedLevelSolsticeDental7",
            "dentSolsticeL7"
          )),
          (data["agent_level_visionSolsticeL7"] = this.getAgentLevel(
            "checkedLevelSolsticeVision7",
            "visionSolsticeL7"
          ));
        contractType = "catContrsL7";
      }

      if (
        Object.keys(nonCategoryContractData).length > 0 &&
        this.checkAllValues(nonCategoryContractData)
      ) {
        this.$notify("At least one contract should be selected", "error");
        return;
      }
      let url = `/api/v2/save-contract-levels`;
      let loader = this.$loading.show();
      let param = {};
      param.agent_id = this.agent.agent_id;
      param.contract_type = contractType;
      param.data = data;
      param.repid = this.$store.getters.rep.id;
      param.action_origin = action_origin;
      Api.post(url, param)
        .then((res) => {
          let message = res.data.message;
          this.$notify(message, "success");
          this.$parent.$emit("onSuccess");
        })
        .catch((err) => {
          this.$notify(err.response.data.message, "error");
          console.log(err);
        })
        .finally(() => {
          loader.hide();
        });
      this.closeModal();
    },

    getAgentLevel(checkedLevelKey, defaultLevelKey) {
      return (
        this.contractLevels[checkedLevelKey] ||
        this.contractLevels[defaultLevelKey] ||
        0
      );
    },
    getContractActions() {
      let url = `/api/v2/get-contract-actions/${this.agent.agent_id}`;
      let loader = this.$loading.show();
      Api.get(url)
        .then((res) => {
          this.actions = res.data.data;
        })
        .catch((err) => {
          this.$notify(err.response.message, "error");
          console.log(err);
        })
        .finally(() => {
          loader.hide();
        });
    },
    getContractLevels() {
      let url = `/api/v2/get-contract-levels/${this.agent.agent_id}`;
      let loader = this.$loading.show();
      Api.get(url)
        .then((res) => {
          this.contractLevels = res.data.data;
        })
        .catch((err) => {
          this.$notify(err.response.message, "error");
          console.log(err);
        })
        .finally(() => {
          loader.hide();
        });
    },
    getAgentContractLevels() {
      /*eslint-disable*/
      let url = `/api/v2/get-contract-levels-new/${this.$store.getters.rep.id}`;
      //let loader = this.$loading.show()

      Api.get(url)
        .then((res) => {
          this.contractAgentLevels = res.data.data;
        })
        .catch((err) => {
          //  this.$notify(err.response.message, 'error')
          console.log(err);
        })
        .finally(() => {
          // loader.hide()
        });
    },
    getData() {
      this.getContractActions();
      this.getContractLevels();
      this.getAgentContractLevels();
    },
    handleLevelSelected(levelData) {
      this.$set(this.contractLevels, levelData.name, levelData.level);
    },
  },
};
</script>
<style>
.select-input {
  border: 1px solid #ccc;
  padding: 0.5rem;
  border-radius: 4px;
  min-width: 100%;
  font-size: 14px;
}

.select-input:focus-visible {
  outline: none;
}

.send-label {
  font-weight: 700;
  color: #000;
  font-size: 14px;
}

.custom-table {
  width: 100%;
  border-collapse: collapse;
}

.table-row {
  display: flex;
}

.table-cell {
  flex: 1;
  padding: 0.5rem;
  border: 1px solid #ccc;
}

.header .table-cell {
  font-weight: 700;
}

.visited {
  border-color: #29bcff;
}

.table-cell {
  flex: 1;
  padding: 8px;
  border-bottom: 1px solid #ddd;
  font-size: 14px;
}

.header {
  background-color: #f2f2f2;
}

.custom-table {
  width: 100%;
  border-collapse: collapse;
}

.table-row {
  display: flex;
}
.contract-btn-container {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  gap: 10px;
}

.contract-btn {
  width: 20% !important;
  margin-right: 1.5%;
}
</style>