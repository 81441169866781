<template>
  <div class="form-group row">
    <label>{{ title }}</label>
    <div class="col-sm-12 d-flex flex-wrap">
      <!-- Render Level 0 -->
      <div class="form-check mr-3">
        <input
          type="radio"
          class="form-check-input"
          id="level0"
          :name="name"
          value="0"
          v-model="selectedLevel"
          :checked="i == +contractLevel"
        />
        <label class="form-check-label" for="level0">None</label>
      </div>

      <!-- Render other levels dynamically -->
      <div class="form-check mr-3" v-for="i in +count" :key="'level' + i">
        <input
          type="radio"
          class="form-check-input"
          :id="'level' + i"
          :name="name"
          :value="i"
          v-model="selectedLevel"
          :checked="i == +contractLevel"
        />
        <label class="form-check-label" :for="'level' + i">Level {{ i }}</label>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    name: {
      type: String,
      default: "level",
    },
    count: {
      type: Number,
    },
    contractLevel: {
      required: true,
    },
  },
  data() {
    return {
      selectedLevel: this.contractLevel,
    };
  },
  watch: {
    contractLevel(newVal) {
      this.selectedLevel = newVal;
    },
    selectedLevel(newVal) {
      this.$emit("level-selected", { level: newVal, name: this.name });
    },
  },
};
</script>
