var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('b-modal', {
    attrs: {
      "id": "showDownlineList",
      "centered": "",
      "size": "lg",
      "hide-footer": ""
    },
    scopedSlots: _vm._u([{
      key: "modal-header",
      fn: function fn() {
        return [_c('div', {
          staticClass: "sendInfo-header w-100 mt-3"
        }, [_c('h4', {
          staticClass: "text-center"
        }, [_vm._v("Active Agent Lists")]), _c('b-button', {
          attrs: {
            "variant": "close-btn"
          },
          on: {
            "click": _vm.closeModal
          }
        }, [_c('i', {
          staticClass: "fas fa-times"
        })])], 1)];
      },
      proxy: true
    }])
  }, [_c('b-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('div', [_c('p', [_vm._v("There are policies under this agent.Deleting this agent its policies will move to "), _c('strong', [_vm._v(_vm._s(_vm.agentDownlineInfo.agent_ga_name) + ".")])])]), _c('div', [_c('p', [_vm._v("The agent have following downlines:")]), _c('div', {
    staticClass: "active-users-list"
  }, _vm._l(_vm.agentDownlineInfo.active_agents, function (activeDownlineList, index) {
    return _c('ul', {
      key: index
    }, [_c('li', {
      staticClass: "text-capitalize"
    }, [_vm._v(_vm._s(activeDownlineList))])]);
  }), 0)]), _c('div', {
    staticClass: "mt-4"
  }, [_c('p', [_vm._v("If you want to delete this agent then these agent's upline will be "), _c('strong', [_vm._v(_vm._s(_vm.agentDownlineInfo.agent_ga_name) + ".")])])]), _c('div', {
    staticClass: "downline-btn-container"
  }, [_c('div', {
    staticClass: "text-center"
  }, [_c('strong', [_vm._v("Are you sure to delete this agent ?")])]), _c('div', {
    staticClass: "row justify-content-center mt-2"
  }, [_c('div', {
    staticClass: "col-xxl-2 col-xl-3 mt-2"
  }, [_c('button', {
    staticClass: "downline-utv-btn",
    attrs: {
      "type": "submit"
    },
    on: {
      "click": _vm.changeToUpline
    }
  }, [_vm._v("Yes")])]), _c('div', {
    staticClass: "col-xxl-2 col-xl-3 mt-2"
  }, [_c('button', {
    staticClass: "downline-utv-btn",
    attrs: {
      "type": "submit"
    },
    on: {
      "click": _vm.closeModal
    }
  }, [_vm._v("No")])])])])])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }