<template>
  <div class="send-info">
    <b-modal id="sendInfo" centered size="lg">
      <template #modal-header>
        <div class="sendInfo-header text-center">
          <h4>Send Information</h4>
          <b-button variant="close-btn" @click="closeModal">
            <i class="fas fa-times" />
          </b-button>
        </div>
      </template>
      <b-container fluid>
        <div class="sendInfo-form">
          <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
            <form @submit.stop.prevent="handleSubmit(sendInfo)">
              <div class="form-group row">
                <div class="col-xxl-6 col-xl-6 col-lg-6 mb-3">
                  <label>To <span class="required-indc">*</span></label>
                  <input
                    type="text"
                    class="info-field"
                    :value="agent.agent_email"
                    disabled
                  />
                </div>

                <!-- <input-select
                  :rules="{ required: true }"
                  labelFor="subject"
                  labelName="Subject"
                  inputName="subject"
                  :vmodel.sync="subjectType"
                  :options="subjects"
                  defaultOption="Select Information Subject"
                  formGroupClass="col-xxl-6 col-xl-6 col-lg-6 mb-3"
                  inputClass="form-control"
                /> -->
                <input-text
                  :rules="{ required: true }"
                  labelFor="subject"
                  labelName="Subject"
                  inputName="subject"
                  :vmodel.sync="form.subject"
                  formGroupClass="col-xxl-6 col-xl-6 col-lg-6 mb-3"
                  placeholder="Enter Subject Here"
                />
              </div>

              <input-textarea
                :rules="{ required: true }"
                labelFor="message"
                labelName="Message"
                inputName="message"
                :vmodel.sync="form.message"
              />

              <div class="msg-block row">
                <div class="col-xxl-6 col-xl-6 col-lg-6 mb-3">
                  <label>To:</label>
                  <ul>
                    <li><strong>Rep Code:</strong> {{ agent.agent_code }}</li>
                    <li><strong>Username:</strong> {{ agent.agent_email }}</li>
<!-- 
                    <li>
                      <strong>Rep Name:</strong>
                      {{ agent.agent_fname + " " + agent.agent_lname }}
                    </li> -->
                  </ul>
                </div>
                <div class="col-xxl-6 col-xl-6 col-lg-6 mb-3">
                  <label>From</label>
                  <ul>
                    <li>
                      <strong>{{ rep.name }}</strong>
                    </li>
                    <li>{{ rep.code }}</li>
                    <li>{{ rep.email }}</li>
                    <li>{{ rep.phone }}</li>
                  </ul>
                </div>
              </div>

              <div class="form-group mb-3">
                <b-button class="univ-btn" type="submit" :disabled="isSend">
                  <template v-if="isSend"> Sending ... </template>
                  <template v-else> Send Email </template>
                </b-button>
              </div>
            </form>
          </ValidationObserver>
        </div>
      </b-container>
    </b-modal>
  </div>
</template>
<script>
import Api from "@/resource/Api";
import { mapGetters } from "vuex";
export default {
  name: "DownlineRepSendInfo",
  data() {
    return {
      form: {
        agent_id: "",
        subject: "",
        message: "",
      },
      subjectType: null,
      subjects: [
        { value: "loginInfo", text: "Rep Login Info" },
        { value: "enrollmentInfo", text: "Rep Enrollment Portal Info" },
      ],
      canSendEmail: true,
      isSend: false,
    };
  },
  props: {
    agent: {
      required: true,
    },
  },
  computed: {
    ...mapGetters({ rep: "rep" }),
  },
  methods: {
    reset() {
      this.form = {
        agent_id: "",
        subject: "",
        message: "",
      };
      this.subjectType = null;
    },
    closeModal() {
      this.$bvModal.hide("sendInfo");
      this.$emit("onClosedModal");
    },
    sendInfo() {
      let url;
      this.isSend = true;
      this.form.agent_id = this.agent.agent_id;
      //   if (this.subjectType == "loginInfo") {
      //     this.form.subject = "Rep Login Info";
      //     url = `v2/send-reps-registration-login-information`;
      //   } else if (this.subjectType == "enrollmentInfo") {
      //     this.form.subject = "Rep Enrollment Portal Info";
      //     url = `v2/send-reps-enrollment-information`;
      //   } else {
      //     this.canSendEmail = false;
      //     this.$notify("Something went wrong", "error");
      //   }

      url = `v2/send-reps-enrollment-information`;
      Api.post(url, this.form)
        .then((res) => {
          this.$notify(res.data.message);
          this.closeModal();
          this.reset();
        })
        .catch((err) => {
          this.$notify(err.response.data.message, "error");
        })
        .finally(() => {
          this.isSend = false;
        });
    },
  },
};
</script>
