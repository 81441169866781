var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "send-info"
  }, [_c('b-modal', {
    attrs: {
      "id": "assignLevel",
      "centered": "",
      "size": "lg",
      "hide-footer": ""
    },
    on: {
      "shown": _vm.getData
    },
    scopedSlots: _vm._u([{
      key: "modal-header",
      fn: function fn() {
        return [_c('div', {
          staticClass: "sendInfo-header w-100 mt-3"
        }, [_c('h4', {
          staticClass: "text-center"
        }, [_vm._v("Set Representative Level")]), _c('b-button', {
          attrs: {
            "variant": "close-btn"
          },
          on: {
            "click": _vm.closeModal
          }
        }, [_c('i', {
          staticClass: "fas fa-times"
        })])], 1)];
      },
      proxy: true
    }])
  }, [_c('b-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('h5', {
    staticClass: "contract-form-heading"
  }, [_vm._v("Select Agent Level")]), _c('div', {
    staticClass: "sendInfo-form"
  }, [_c('form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
      }
    }
  }, [_c('assign-level-premier-contract', {
    attrs: {
      "contractLevel": _vm.contractLevels.pec,
      "name": "checkedLevelPec",
      "count": "7",
      "title": "Premier | Elite Agent Level"
    },
    on: {
      "level-selected": _vm.handleLevelSelected
    }
  }), _c('contract-group', {
    attrs: {
      "contractLevel": _vm.contractLevels.alc,
      "count": "7",
      "title": "Ancillary | LifeStyle Agent Level",
      "name": "checkedLevelAlc"
    },
    on: {
      "level-selected": _vm.handleLevelSelected
    }
  })], 1)]), _c('hr'), _c('div', {
    staticClass: "contract-btn-container"
  }, [_c('b-button', {
    staticClass: "univ-btn contract-btn",
    attrs: {
      "variant": "primary",
      "data-dismiss": "modal"
    },
    on: {
      "click": _vm.syncAgentLevel
    }
  }, [_vm._v("Assign")])], 1)])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }