<template>
  <b-modal
    id="defaultOtpModal"
    centered
    size="md"
    hide-footer
    no-close-on-backdrop
    v-model="show"
  >
    <template #modal-header>
      <div class="otp-modal-header">
        <b-button variant="close-btn" @click="closeModal">
          <i class="fas fa-times"></i>
        </b-button>
      </div>
    </template>
    <b-container fluid>
      <div class="row">
        <div class="col-12">
          <div class="otp-card">
            <div class="otp-head text-center">
              <h1>Default OTPs</h1>
              <p>
                These are default OTPs that can be used for login and reset
                password.
              </p>
            </div>
            <div class="otp-body">
              <b-skeleton-table
                v-if="isLoading"
                :rows="5"
                :columns="1"
                :table-props="{ bordered: true, striped: true }"
              ></b-skeleton-table>
              <ul v-if="otpList.length">
                <li
                  v-for="(row, index) in otpList"
                  :key="index"
                  :class="status[row.is_used]"
                >
                  <span>{{ row.otp }}</span>
                </li>
              </ul>
              <p class="text-center no-record" v-else>No Record Found</p>
            </div>
          </div>
        </div>
      </div>
    </b-container>
  </b-modal>
</template>
<script>
const USER_TYPE = {
  A: "agent_id",
  G: "group_id",
  M: "user_id",
};
const STATUS = {
  0: "is-usable",
  1: "is-nonusable",
};
import Api from "@/resource/Api";
import Helper from "@/resource/Helper";
export default {
  name: "DefaultOtpModal",
  props: {
    /**
     *object with info of current user
     *member , group or agent
     */
    row: {
      required: true,
      default: {},
    },
    /**
     * Agent:'A',Member:'M',Group:'G'
     */
    rowType: {
      required: true,
      default: "",
    },
  },
  data: () => ({
    show: false,
    otpList: [],
    status: STATUS,
    isLoading: false,
  }),
  methods: {
    closeModal() {
      this.otpList = [];
      this.$emit("onCloseModal");
      this.$bvModal.hide("defaultOtpModal");
    },
    getUserInfo() {
      if (!this.rowType && Object.keys(this.rows).length) {
        return {};
      }

      return {
        user_id: this.row[USER_TYPE[this.rowType]],
        usertype: this.rowType,
      };
    },
    getOtpList() {
      this.isLoading = true;
      let url =
        "v2/default-otp/get" + `?${Helper.objToUrlParams(this.getUserInfo())}`;
      Api.get(url)
        .then((res) => {
          this.otpList = res.data.data;
        })
        .catch((err) => {
          this.otpList = [];
          this.$notify(err.response.data.message, "error");
          this.closeModal();
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
  watch: {
    show(value) {
      if (value) {
        this.getOtpList();
      }
    },
  },
};
</script>
<style>
#defaultOtpModal header {
  display: flex;
  border: none;
}
</style>

<style scoped>
.otp-modal-header button {
  position: absolute;
  top: -15px;
  right: -15px;
  background: #fff;
  color: #000;
  border: 0;
  box-shadow: -2px 2px 13px -4px rgb(0 0 0 / 46%);
  border-radius: 60px;
  font-size: 18px;
  width: 45px;
  height: 45px;
  align-items: center;
  justify-content: center;
  display: flex;
}

.no-record {
  font-size: 14px;
}

.otp-head {
  margin-bottom: 2rem;
}

.otp-head h1 {
  font-size: 20px;
  font-weight: 700;
}

.otp-head p {
  color: #778699;
  font-size: 14px;
  margin: 1rem 0;
}

.otp-body ul {
  margin: 0;
  padding: 0;
}
.otp-body ul li {
  list-style: none;
  padding: 10px 8px;
  margin-bottom: 1rem;
  border-radius: 8px;
  font-size: 15px;
  letter-spacing: 3px;
}
.is-usable {
  background: rgba(41, 188, 255, 0.1) !important;
  color: #03c3ec;
}

.is-nonusable {
  background: #fdeeed !important;
  color: #ea584e;
}
</style>