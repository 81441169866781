<template>
  <div class="send-info">
    <b-modal id="assignLevel" @shown="getData" centered size="lg" hide-footer>
      <template #modal-header>
        <div class="sendInfo-header w-100 mt-3">
          <h4 class="text-center">Set Representative Level</h4>
          <b-button variant="close-btn" @click="closeModal">
            <i class="fas fa-times" />
          </b-button>
        </div>
      </template>
      <b-container fluid>
        <h5 class="contract-form-heading">Select Agent Level</h5>
        <div class="sendInfo-form">
          <form @submit.prevent>
            <assign-level-premier-contract
              :contractLevel="contractLevels.pec"
              name="checkedLevelPec"
              count="7"
              title="Premier | Elite Agent Level"
              @level-selected="handleLevelSelected"
            />
            <contract-group
              :contractLevel="contractLevels.alc"
              count="7"
              title="Ancillary | LifeStyle Agent Level"
              name="checkedLevelAlc"
              @level-selected="handleLevelSelected"
            />
          </form>
        </div>
        <hr />
        <div class="contract-btn-container">
          <b-button
            class="univ-btn contract-btn"
            variant="primary"
            @click="syncAgentLevel"
            data-dismiss="modal"
            >Assign</b-button
          >
        </div>
      </b-container>
    </b-modal>
  </div>
</template>
<script>
import ContractGroup from "./ContractGroup.vue";
import AssignLevelPremierContract from "./AssignLevelPremierContract";
import Api from "@/resource/Api";
export default {
  name: "AssignLevelModal",
  components: {
    ContractGroup,
    AssignLevelPremierContract,
  },
  props: {
    agent: {
      required: true,
    },
  },
  data() {
    return {
      actions: [],
      contractLevels: {},
    };
  },
  methods: {
    closeModal() {
      this.$bvModal.hide("assignLevel");
      this.$emit("onClosedModal");
    },
    getData() {
      this.contractLevels = {
        pec: this.agent.agent_level,
        alc: this.agent.agent_level_2,
      };
    },
    handleLevelSelected(levelData) {
      this.$set(this.contractLevels, levelData.name, levelData.level);
    },

    syncAgentLevel() {
      const url = `v2/agent/update-agent-level-status`;
      let loader = this.$loading.show();
      this.form = {
        agent_id: this.agent.agent_id,
        agent_gid: this.agent.gid,
        premier_level: this.contractLevels.checkedLevelPec
          ? this.contractLevels.checkedLevelPec
          : this.contractLevels.pec
          ? this.contractLevels.pec
          : 0,
        ancillary_level: this.contractLevels.checkedLevelAlc
          ? this.contractLevels.checkedLevelAlc
          : this.contractLevels.alc
          ? this.contractLevels.alc
          : 0,
      };

      Api.post(url, this.form)
        .then((res) => {
          this.$notify(res.data.data.message, "success");
          this.closeModal();
          this.$parent.$emit("onSuccess");
        })
        .catch((err) => {
          this.$notify(err.response.data.message, "error");
          console.log(err);
        })
        .finally(() => {
          loader.hide();
        });
    },
  },
};
</script>
<style>
.contract-form-heading {
  margin-bottom: 30px;
}
</style>
