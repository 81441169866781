var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "id": "defaultOtpModal",
      "centered": "",
      "size": "md",
      "hide-footer": "",
      "no-close-on-backdrop": ""
    },
    scopedSlots: _vm._u([{
      key: "modal-header",
      fn: function fn() {
        return [_c('div', {
          staticClass: "otp-modal-header"
        }, [_c('b-button', {
          attrs: {
            "variant": "close-btn"
          },
          on: {
            "click": _vm.closeModal
          }
        }, [_c('i', {
          staticClass: "fas fa-times"
        })])], 1)];
      },
      proxy: true
    }]),
    model: {
      value: _vm.show,
      callback: function callback($$v) {
        _vm.show = $$v;
      },
      expression: "show"
    }
  }, [_c('b-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-12"
  }, [_c('div', {
    staticClass: "otp-card"
  }, [_c('div', {
    staticClass: "otp-head text-center"
  }, [_c('h1', [_vm._v("Default OTPs")]), _c('p', [_vm._v(" These are default OTPs that can be used for login and reset password. ")])]), _c('div', {
    staticClass: "otp-body"
  }, [_vm.isLoading ? _c('b-skeleton-table', {
    attrs: {
      "rows": 5,
      "columns": 1,
      "table-props": {
        bordered: true,
        striped: true
      }
    }
  }) : _vm._e(), _vm.otpList.length ? _c('ul', _vm._l(_vm.otpList, function (row, index) {
    return _c('li', {
      key: index,
      class: _vm.status[row.is_used]
    }, [_c('span', [_vm._v(_vm._s(row.otp))])]);
  }), 0) : _c('p', {
    staticClass: "text-center no-record"
  }, [_vm._v("No Record Found")])], 1)])])])])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }